import React, {useState, useEffect} from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-labels';
// import ChartDataLabels from 'chartjs-plugin-labels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

const IndustryBreakdown = ({location, categories, className, host}) => {
    //State variable to keep track of the articles being fetched
    const [fetchedArticles,setFetchedArticles] = useState([]);

    //State variable to prevent multiple calls on initial render
    const [initialRender, setInitialRender] = useState(true);
    
    //useEffect to execute whenever parameter is changed to fetch new data!
    useEffect(() => {
      //Fetch industry/sector data
      const fetchData = async () => {
          //Endpoint for fetching industry / sector!
          try {
              //Endpoint for fetching count from industries / sectors
              let articleEndpoint = `${host}/api/industryBreakdown/`
              //Construct the query strings for the requested articles
              const queryString = `cont=${location[0].toLowerCase()}&country=${location[1].toLowerCase()}&city=${location[2].toLowerCase()}&parentCat=${categories[0].toLowerCase()}&childCat=${categories[1].toLowerCase()}`
              const fetchURL = `${articleEndpoint}?${queryString}`
              //Asynchronous fetch of articles since don't know how long fetch will take
              const articles = await fetch(fetchURL);
              const articleData = await articles.json();
              const parsedData = JSON.parse(articleData)
              setFetchedArticles(parsedData);
          }
          catch {
              console.log("Error retrieving data! Please try again.")
          }
      };
      //Call function to fetch articles
      fetchData();
  },[categories,location]);

  useEffect(() => {
    //If initial render, skip (to avoid duplicate render)
    if (initialRender) {
      setInitialRender(false);
      return;
    }
  },[fetchedArticles])

  // Count the number of articles in each category
  // const categoryCounts = articles.reduce((counts, article) => {
  //   const category = article[13];
  //   counts[category] = (counts[category] || 0) + 1;
  //   return counts;
  // }, {});

  //Change data returned by API into 2 separate arrays with categories and count
  const categoryLabels = fetchedArticles.map((item)=>item[0]);
  const categoryCounts = fetchedArticles.map((item)=>item[1]);

  


  // Prepare the data for the chart
  const chartData = {
    labels: categoryLabels,
    datasets: [
      {
        data: categoryCounts,
        //Make sure there's a unique background color for every sector/industry!
        backgroundColor: [
          'rgba(255, 99, 132, 1)',   // Red
          'rgba(54, 162, 235, 1)',   // Blue
          'rgba(255, 206, 86, 1)',   // Yellow
          'rgba(75, 192, 192, 1)',   // Green
          'rgba(153, 102, 255, 1)',  // Purple
          'rgba(255, 159, 64, 1)',   // Orange
          'rgba(255, 0, 0, 1)',      // Dark Red
          'rgba(0, 0, 255, 1)',      // Dark Blue
          'rgba(0, 255, 0, 1)',      // Dark Green
          'rgba(128, 0, 128, 1)',    // Dark Purple
          'rgba(255, 140, 0, 1)',    // Dark Orange
          'rgba(0, 128, 128, 1)',    // Teal
          'rgba(128, 128, 0, 1)',    // Olive
          'rgba(128, 0, 0, 1)',      // Maroon
          'rgba(0, 0, 128, 1)',      // Navy
          'rgba(219, 64, 82, 1)',    // Crimson
          'rgba(0, 166, 147, 1)',    // Turquoise
          'rgba(255, 204, 92, 1)',   // Gold
          'rgba(162, 113, 194, 1)',  // Lavender
          'rgba(242, 119, 122, 1)',  // Salmon
          'rgba(255, 165, 0, 1)',    // Orange
          'rgba(0, 128, 0, 1)',      // Green
          'rgba(70, 130, 180, 1)',   // Steel Blue
          'rgba(128, 0, 128, 1)',    // Purple
          'rgba(255, 0, 255, 1)',    // Magenta
          'rgba(0, 128, 128, 1)',    // Teal
          'rgba(255, 69, 0, 1)',     // Orange-Red
          'rgba(0, 255, 0, 1)',      // Lime
          'rgba(128, 128, 128, 1)',  // Gray
          'rgba(218, 112, 214, 1)',  // Orchid
          'rgba(95, 158, 160, 1)',   // Cadet Blue
        ],
        //Increase the border of the dataset if it is the currently selected industry! (Increase border from 1 to 4)
        borderWidth: categoryCounts.map((count, index) => (categoryLabels[index] === categories[1] ? 4 : 1)),
      },
    ],
  };

  // Customize chart by setting options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        //Display the legend labels underneath the chart!
        display: true,
        position: 'bottom',
      },
    },
  
    layout: {
      padding: {
        bottom: 0,
      },
    },
  };

  return (
    <div className={className}>
      <h2>Industry Composition</h2>
      <div>
        <Doughnut data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};


export default IndustryBreakdown;
// export default IndustryBreakdown;