// Ref: https://www.ultimateakash.com/blog-details/Ii1DOGAKYAo=/How-To-Integrate-Leaflet-Maps-in-React-2022

import React, { useRef, useState, useEffect} from "react";
import Leaflet from "leaflet"
import "leaflet/dist/leaflet.css" 
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, ZoomControl } from "react-leaflet"
import markerIcon from "leaflet/dist/images/marker-icon.png"
import markerShadow from "leaflet/dist/images/marker-shadow.png"
import markerRetina from "leaflet/dist/images/marker-icon-2x.png"
import './map.component.css'
import {countryMarkers, countryToContinentMapping} from './countries.js'
// import {continentMarkers} from './continents.js'
import {cityMarkers, locationInfo} from './cities.js'
import {yourGeojsonData} from './contCoords.js'
import L from 'leaflet'
import InteractiveMap from './geoJSON.jsx'
import SelectionBox from './selectionBox.jsx'
import ReturnedResults from './returnedResults.jsx'
import CategoryFilter from './categoryFilter.jsx'

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: markerRetina,
    iconUrl: markerIcon,
    shadowUrl: markerShadow
});

const MapComponent = ({setNewLocation, setLocInfo, defaultZoom, resetMap}) => {
    /*useRef used to hold mutable values that persist across renders. Unlike state, doesn't trigger re-render
    when variable is changed. Can use useRef to get reference to DOM element to get access to properties & methods
    If have value that needs to be accessed by multiple functions/hooks useRef will persist across renders
    If need to keep track of variable between renders, useRef is good to go*/
    const mapRef = useRef();

    //useState used to manage state of component & re-render whenever the state changes
    const [zoom,setZoom]=useState(defaultZoom);
    const [allMarkers, setAllMarkers] = useState([]);
    const [visibleMarkers, setVisibleMarkers] = useState([]);
    const [hoveredFeature, setHoveredFeature] = useState(null);
    const [contCoords, setContCoords] = useState([]);
    const mapUpdating = useRef(false);

    //State variable to keep track of zoom level (country, city)
    const [zoomLevel, setZoomLevel] = useState('');

    //state variable to keep track of the articles
    const [currentArticles, setCurrentArticles] = useState([]);

    //Object to set the size of the map
    const containerStyle = {
        width: "100%",
        height:"100%",
        zIndex: "1",
        border: "1px solid #000",
        borderRadius: "50px"
    }

    //Object to set the initial view of the map 
    const center = {
        lat: 28.626137,
        lng: -29.53125
    }

    //Function to record the longitude, latitude and zoom when the map is clicked
    const mapClicked = async (event) => {
        // console.log(event.latlng.lat, event.latlng.lng)
        // console.log(zoom);
        let testCoord = "["+event.latlng.lng+","+event.latlng.lat+"],";
        setContCoords((prevConstCoords => [...prevConstCoords, testCoord]));
        // console.log(event.latlng)
    };

    //Function to handle whenever a marker is clicked
    const handleMarkClick = (marker) => {
        //If zoom level is country, update with country and corresponding continent
        if (zoomLevel == "country")
        {
            const newLoc = [countryToContinentMapping[marker.name],marker.name,"Any"];
            setNewLocation(newLoc);
            //Set the location info to corresponding country
            //locationInfo is object with hiearachical definitions for conts->countries->cities

            try {setLocInfo(locationInfo[countryToContinentMapping[marker.name]][marker.name]?.selfInfo);}
            catch{setLocInfo({"Error": "Invalid Location"});};
        }

        //If zoom level is continent, update with continent, country and city
        else if (zoomLevel == "city")
        {
            const newLoc = [marker.continent,marker.country,marker.name];
            setNewLocation(newLoc);
            //Set the location info to corresponding city
            //locationInfo is object with hiearachical definitions for conts->countries->cities
            try {setLocInfo(locationInfo[marker.continent][marker.country][marker.name])}
            catch{setLocInfo({"Error": "Invalid Location"});};
        }
        console.log(`Pressed: ${marker.name}`) 
    };

    //Function to remove pins to ensure everything displayed properly when zooming in and out
    //Currently not required, problem seen was due to duplicate key entries
    /*
    const clearMarkers = (markersToKeep) => {
        console.log(markersToKeep);
        mapRef.current.eachLayer((layer) => {
            if (layer instanceof L.Marker && markersToKeep.includes(layer)) {
                console.log(layer._popup.options)
                mapRef.current.removeLayer(layer);
            }
        });
    };
    */

    //Function to update the visible markers (what the user actual sees)
    const updateVisibleMarkers = (newMarkers) => {
        //Gets the current bounds. ? ensures that the mapRef.current object doesn't throw runtime error if doesn't exist
        const bounds = mapRef.current?.getBounds();

        //Set the markers to only those with map's current bounds
        const newVisibleMarkers = newMarkers.filter((marker) => bounds.contains([marker.lat, marker.lon]));
        
        //Update the visible markers with the ones in the bounds
        setVisibleMarkers(newVisibleMarkers);
    };

    //Function to handle when the map is zoomed in on
    const handleZoom = (newZoom) => {
       
        setZoom(newZoom);
        console.log("NEW ZOOM: ",newZoom);
        //Change what markers are displayed (continent, country, city) based on zoom value
        if (newZoom < 3) {
            setAllMarkers([]);
            setZoomLevel('');
        }
        else if (newZoom > 2 && newZoom < 5) {
            setAllMarkers(countryMarkers);
            setZoomLevel('country');
        }
        else {
            setAllMarkers(cityMarkers);
            setZoomLevel('city');
        }
        
    };

    useEffect(()=>{
        handleZoom(defaultZoom);
        mapRef.current?.setView(center,defaultZoom);
    },[resetMap]);
    //Function to handle generic map events
    const MapEvents = () => {
        
        //Function to ensure that the only the primary instance is seen (so the map doesn't wrap on zooms and moves)
        const updateMapBounds = (map) => {
            if (mapUpdating.current) { return; }
            mapUpdating.current = true;
            const {lat, lng} = map.getCenter();
            
            const bounds = map.getBounds();
            const maxLng = bounds.getEast();
            const minLng = bounds.getWest();

            // Check if the map needs to be adjusted
            let newLng=lng;

            if (lng > maxLng) {
              newLng = maxLng;
            } else if (lng < minLng) {
              newLng = minLng;
            }
      
            // Update the map center if needed
            if (newLng !== lng) {
              //map.setView([lat, newLng], map.getZoom(), { animate: false });
              map.panTo([lat, newLng], {animate: false});
            }
            
            mapUpdating.current=false;
          };

        //useMapEvents = generic leaflet hook to handle events on map
        //Synchronous (executes when map event occurs in order it occurs)
        const map = useMapEvents({

            //When zoom happens, get current zoom and pass it to handleZoom function
            // zoomend: (event) => {
            //     console.log("HELLO ZOOM" + event.type);
            //     const newZoom = mapRef.current?.getZoom();
            //     if (newZoom) 
            //     {
            //         handleZoom(newZoom);
            //     }
            // },

            //When map is moved, update the visible markers. Zoomend and moveend triggered on zooms, so doing in one
            //Had problem with moveend event being fired before updating the zoom state!
            moveend: (event) => {
                console.log("HELLO ZOOM" + event.type);
                const newZoom = mapRef.current?.getZoom();
                //Check if zoom has changed, if yes handle the zoom
                if (newZoom != zoom) 
                {
                    handleZoom(newZoom);
                }
                //If the zoom hasn't changed just update markers
                else{
                    const newMarkers = allMarkers;
                    updateVisibleMarkers(newMarkers);
                }

            },
            move: (event) => {
                updateMapBounds(map);
            }

        });
        //Don't want function to return anything, just handle events
        return null;
    };

    //Get the visible markers when allMarkers updates (when zoom)
    //Doing outside the zoomend function since it useEffect has to be in capitilized function (naming convention)
    //Using useEffect so that updateVisibleMarkers is called whenever allMarkers state changes (due to state changing asynchronously)
    useEffect(() => {
        const newMarkers = allMarkers;
        console.log(newMarkers);
        updateVisibleMarkers(newMarkers);
    }, [allMarkers]);

    const maxBounds = L.latLngBounds(
        L.latLng(-90,-80),
        L.latLng(90, -75)
    );
    

    return (
        <>
        {/* Container for the map.
        setting ref to mapRef makes mapRef.current equal to the instance of the leaflet map */}
  
            <MapContainer
                style={containerStyle}
                center={center}
                zoom={zoom}
                scrollWheelZoom={true}
                ref={mapRef}
                onZoomEnd={handleZoom}
                maxBounds={[
                    [Infinity, 195], // Northeast
                    [-Infinity, -170], // Southwest
                ]}
                maxBoundsViscosity={1}
                minZoom={2}
                maxZoom={10}
                zoomControl={false} //Diable default zoom control
            >
                {/* Possible map overlays:
                https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
                https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}
                 */}
                {/* https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png */}
                {/* Component that contains attribution, source of map and  */}
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                    //To control layer's rendering order & stacking on other layers (tilePane is just default value)
                    pane="tilePane"
                    noWrap={true}
                    className="attribution"
                />
                {/* Component to have interactive shape files. handleGeoCatClick, passes function to update 
                state variable for what articles are fetched. Only display when continent zoom level ('')
                This is to ensure doesn't highlight when zoomed in*/}

                {zoomLevel === '' &&
                (<InteractiveMap mapRef={mapRef} setLocation={setNewLocation} setLocInfo={setLocInfo}/>)}

                {/* Component to trigger certain map events (zoom, move, etc) */}
                <MapEvents />

                {/* Component to trigger the click map event */}
                <MapContent
                    onClick={mapClicked}
                />

                {/* Actually rendering the visible markers */}
                {visibleMarkers.map((visibleMarker) => (
                    <Marker
                        //Ensures that each key is unique!
                        key={`${visibleMarker.name}-${visibleMarker.lat}-${visibleMarker.lon}`}
                        position={[visibleMarker.lat, visibleMarker.lon]}
                        //Function called when marker is clicked
                        eventHandlers = {{click: () => handleMarkClick(visibleMarker)}}>
                        {/* Info that appears when marker is clicked */}
                        {/* <Popup>{visibleMarker.info}</Popup> */}
                    </Marker>
                    
                ))}
                <ZoomControl position="topleft"/>
            </MapContainer>

        {/* <SelectionBox className="selectionBox" />
        <FilterBox className="filterBox" />
        <h1>Analytics Here?</h1> */}
        {/* Pass the state variable determining what articles are fetched! */}
        {/* <ReturnedResults fetchQuery={fetchParameters} /> */}
        </>
    );
};

// Might not be required in future. Just example of useMapEvents to handle map click
const MapContent = ({ onClick }) => {  
    const map = useMapEvents({
        click: event => onClick(event)
    }) 
    return null;
}



export default MapComponent;