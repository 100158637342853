import './App.css';
import MapComponent from "./components/map.component";
import CategoryFilter from './components/categoryFilter';
import CurrentGeoSelection from './components/currentGeoSelection';
import MapLayout from './components/mapLayout';
import MarketingLayout from './components/marketingTemplate';
import Email from './components/infoPages/email'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AnalyzeNews from './components/parentAnalytics';
import './components/mapLayout.css'
import Dashboard from './components/dashboard/dashboard'
import React, { useState, useEffect, useMemo } from 'react';
import LoginPage from './components/login';
import { useAccessToken } from './globalStateContext';
import { GlobalArticleStates } from './globalStateContext';
import { Navigate } from "react-router-dom";
import Button from '@mui/material/Button';
import ReactDOM from 'react-dom';
import LoadingScreen from './components/loadingScreen';
import LandingPage from './components/landingPage';
import SignupEmail from './components/infoPages/email';
import PrivacyPolicy from './components/privacy-policy';

function App() {

  const {accessToken, makeRequest, handleResize, setCurrentPage} = useAccessToken();

  //state variable to keep track of locations (format is [continent,country,city])
  const [location, setLocation] = useState(["Any","Any","Any"])

  //State variable to keep track of categories (format is [sectory,industry])
  const [categories, setCategories] = useState(["Any","Any"])

  //State variable to keep track of whether dashboard should be visible
  const [dashBoardVisible, setDashboardVisible] = useState(false);

  //State variables to only get dashboard created when analyze is pressed
  const [locationCopy, setLocationCopy] = useState(["Any","Any","Any"]);
  const [categoriesCopy, setCategoriesCopy] = useState(["Any","Any"]);


  //State variable to reset map (when rest filters is pressed)
  const [resetMap, setResetMap] = useState(true);

  //State variable to keep track of the information of the location in question
  const [locInfo, setLocInfo] = useState(false);

  //Function to update categories from children
  const updateCategories = (newCategories) => {
    setCategories(newCategories);
  }

  //Memoized Dashboard component to prevent unnecessary re-renders
  //Allows to to only render when dependents (locationCopy and categoriesCopy) change
  //Default is whenever parent re-renders, all children also re-render!
  const MemoizedDashboard = useMemo(() => {
    return <Dashboard location={locationCopy} categories={categoriesCopy} host={process.env.REACT_APP_DJANGO_BACKEND_HOST}/>;
  }, [locationCopy, categoriesCopy]);

  //Right now, defining category in parentAnalytic table!
  // const [parentCategory, setParentCategory] = useState('')
  // const [childCategory, setChildCategory] = useState('')

  //Method to set the new location. Passed to child components to update location
  const setNewLocation = (newLocation) => {
    setLocation(newLocation);
  }

  //Function to reset filters
  const resetFilters = () =>
  {
    setLocation(["Any","Any","Any"]);
    setCategories(["Any","Any"]);
    //Reset the map also when filters reset
    //Reason for !resetMap is to get prop value to change to MapComponenter every time
    //This will allow useEffect to be triggered for reset function (since this prop changes)
    setResetMap(!resetMap);

    //Set location info to null as well
    setLocInfo(null);
  }

  //Method to set state variable scroll to next page (for dashboard)
  const scrollToNextPage = () => {
    if (dashBoardVisible)
    {
      const nextPageOffset = window.innerHeight; // Adjust the multiplier as needed
      window.scrollTo({ top: nextPageOffset, behavior: 'smooth' });
    }
    setDashboardVisible(true);

    //Function to send metrics on user's top viewed location / category. Called when analyze pressed
    async function sendUserMetrics() {
      const response = await makeRequest("POST","api/userMetrics",
        {"continent":location[0].toLowerCase(),"country":location[1].toLowerCase(),"city":location[2].toLowerCase(),
        "sector":categories[0].toLowerCase(),"industry":categories[1].toLowerCase()});
      if (!response.error)
      {
        console.log("Metrics sent successfully!");
      }
      //Handle error
      else
      {
        console.log("ERROR!");
      }
    };
    //Update location and categories copy to only have dashboard produced when button pressed
    //This is because location and categories passed to child components to update, so don't want dashboard generated then
    setLocationCopy(location);
    setCategoriesCopy(categories);

    //Send the metrics to the backend (to record user's category and location)
    sendUserMetrics();
    setCurrentPage(1);
  };

  //Method to scroll if dashboard state variable is set to true (from false)
  useEffect(() => {
    if (dashBoardVisible)
    {
      console.log("SCROLLING!!!");
      const nextPageOffset = window.innerHeight; // Adjust the multiplier as needed
      window.scrollTo({ top: nextPageOffset, behavior: 'smooth' });
    }
  },[dashBoardVisible])

  //Make it so user can't manually scroll on this page
  document.body.style.overflow = 'hidden';

  //Return the login page if the user doesn't have a access token!
  console.log("HIGHER LEVEL ACCESS TOKEN: ",accessToken);
  if (!accessToken) {
    return <Navigate to="/landingPage" />
  }
  
  return (
    <Navigate to="/landingPage" />
  );

}

export default App;
