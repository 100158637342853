import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AboutPage from './components/infoPages/about';
import AccountPage from './components/infoPages/account';
import ContactPage from './components/infoPages/contact';
import FeaturesPage from './components/infoPages/features';
import SupportPage from './components/support.jsx';
import MissionPage from './components/infoPages/mission';
import ResourcesPage from './components/infoPages/resources';
import LoginPage from './components/login';
import LandingPage from './components/landingPage';
import useToken from './manageToken';
import SignupEmail from './components/infoPages/email';


import { AccessTokenProvider } from './globalStateContext';

//To add routes
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//This is to make the react-modal: App element is not defined error go away!
import Modal from 'react-modal';
import PrivacyPolicy from './components/privacy-policy';
import TermsAndConditions from './components/toc';
Modal.setAppElement('#root');

//This way (with ReactDOM.createRoot), is new to react 18 which allows for increased rendering performance
//Concurrent rendering behavior caused some comonents to render twice
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
//Switched from ReactDOM.createRoot to ReactDOM.render to avoid duplicate renders of some components!
//This way does traditional tree rendering method and does not do concurrent renders!

//Page to log the user out
function LogoutPage({expireToken}) {
  console.log("LOGGING OUT!");
  async function logout()
  {
    //Call the expire function to blacklist token
    await expireToken();
    //Redirect the user to the login page once token's expired
    window.location.replace('/login');
  }
  logout();
  // return <Navigate to="/" replace />
}
<link href="https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap" rel="stylesheet"></link>

//Wrap in own component, so can call the useToken function to manage access (can't call custom function from parent)

function AppWrapper () {
  const tokenSuite = useToken();
  return (
    <BrowserRouter>
    <Routes>
      {/* <Route path="/" element={<App tokenSuite={tokenSuite} />}/> */}
      <Route path="/" element={<Navigate to="/landingPage" />}/>
      <Route path="/support" element={<SupportPage />} />
      <Route path="contact" element={<ContactPage />}/>
      <Route path="features" element={<FeaturesPage />}/>
      <Route path="login" element={<LoginPage />} />
      <Route path="landingPage" element={<LandingPage /> } />
      <Route path="privacy-policy" element={<PrivacyPolicy /> } />
      <Route path="toc" element={<TermsAndConditions /> } />
            <Route path="logout" element={<LogoutPage expireToken={tokenSuite["expireToken"]}/>}/>
    </Routes>
  </BrowserRouter>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <AccessTokenProvider>
      <AppWrapper />
    </AccessTokenProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
