import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import "./CtaSection.css"; // Include the CSS for styling
import Captcha from "./captcha"


function CtaSection() {

  const [email, setEmail] = useState(''); //For keeping track of user's email
  const [emailMessage, setEmailMessage] = useState(); //For keeping track if email submit was success or not
  const [captchaToken, setCaptchaToken] = useState();
  const handleCaptchaVerify = (token) =>{
      setCaptchaToken(token);
  };

  const submitEmail = async (e, source) => {
    setEmail('');
    e.preventDefault();
    const emailInfo={
        "email":email,
        "source":source
    };
    console.log(`${process.env.REACT_APP_LANDING_PAGE_BACKEND}`);
    //POST Request
    const res = await fetch(`${process.env.REACT_APP_LANDING_PAGE_BACKEND}`, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    'x-functions-key': `${process.env.REACT_APP_LANDING_PAGE_KEY}`
    },
    body: JSON.stringify(emailInfo),
    });

    //If the response is success, let user know their email was saved successfully!
    if (res.ok) {
        setEmailMessage("*Email Submitted Successfully!")
    }
    //If response is not a success, report error and wipe password field
    else {
    setEmailMessage("*Error adding email! Please try again or try with a different email.")
    setEmail('');
    }
}

  return (
    <section id="CTA-section" className="cta-section">
      <div className="cta-content">
        <h2 className="cta-title">Ready to Augment your Perspective?</h2>
        <p className="cta-subtitle">
          Join our beta program today and shape the future of Trendcaster.
        </p>
        <form className="email-form" onSubmit={(e)=>submitEmail(e,"landingPageBottom")}>
            {/* Honeypot field to catch bots that auto fill fields */}
            <input type="text" name="verifier" Style={"display:none"} value="" /> 
            <input 
                type="email" className="email-input"  placeholder="Enter your email" 
                onChange={(e) => setEmail(e.target.value)}        
                value={email}   
                required
                maxlength="100"
            />
            {/* Commenting out link. Make them type email, then redirect them to page where have to type email again? */}
            {/* <Link to="/signup"> */}
                <button type="submit" className="cta-button-main">
                    Get Started
                </button>
            {/* </Link> */}
          </form>
          {emailMessage && <div style={{color: "red"}}>{emailMessage}</div>}
            
      </div>

      
    </section>
  );
}

export default CtaSection;
