import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./privacy.css"; // Make sure this file exists for your custom styles

export default function PrivacyPolicy() {
  const sectionRefs = {
    introduction: useRef(null),
    dataCollection: useRef(null),
    dataUsage: useRef(null),
    thirdParties: useRef(null),
    userRights: useRef(null),
    contactUs: useRef(null),
  };

  return (
    <div className="terms-container">
      {/* Header with Logo */}
      <header className="header">
        <Link to="/" className="logo">
          TRENDCASTER
        </Link>
      </header>

      {/* Main Content */}
      <div className="terms-content">
        <div className="terms-sections">
          <p>Updated Sep 3, 2024</p>
          <p>
            This Privacy Policy provides you with important information about how
            Trendcaster Analytics handles your personal information. Your privacy
            is important to us, and safeguarding your privacy is fundamental to
            our mission.
          </p>

          <section id="introduction" ref={sectionRefs.introduction}>
            <h2>Introduction</h2>
            <p>
              At Trendcaster, we are committed to protecting your privacy and
              ensuring the security of your personal information. This web
              application conforms to the Personal Information and Electronic
              Documents Act (PIPEDA), which is the federal privacy law for
              private-sector organizations located in Canada. This privacy policy
              describes the type of information we collect, how we share that
              information, and the measures we take to protect your personal
              information. By using our website, you agree to the terms of this
              privacy policy.
            </p>
          </section>

          <section id="dataCollection" ref={sectionRefs.dataCollection}>
            <h2>What Information Do We Collect?</h2>
            <h3>Personal Information</h3>
            <p>
              When you use our services or create an account on Trendcaster.com,
              we may collect personal information, such as your name, age, email
              address, passwords, usernames, contact preferences, authentication
              data, and billing information. We may also collect any other
              information that you choose to provide to us.
            </p>
            <h4>Billing Information</h4>
            <p>
              Although currently we do not collect payments, in the future we may
              collect data necessary to process your payment if you make
              purchases, such as your payment instrument number (e.g., credit
              card number), and the security code associated with your payment
              instrument.
            </p>
            <h3>Non-Personal Information</h3>
            <p>
              We may collect non-personal information about your use of our
              website, including your IP address, browser type, operating system,
              and the pages you visit. This information is used to improve our
              services and understand user behavior on our platform.
            </p>
            <h3>Cookies and Similar Technologies</h3>
            <p>
              We use cookies and similar technologies to enhance your user
              experience and collect information about your use of our website.
              You can control the use of cookies through your browser settings.
              However, disabling cookies may affect the functionality of our
              website.
            </p>
          </section>

          <section id="dataUsage" ref={sectionRefs.dataUsage}>
            <h2>How We Use Your Information?</h2>
            <ul>
              <li>To Provide You Our Services</li>
              <li>To Improve Our Services and Site</li>
              <li>To Comply with Legal Obligations and Requirements</li>
              <li>Responding to Your Inquiries</li>
              <li>For Marketing and Promotional Purposes</li>
              <li>
                Sending you newsletters and offers of products, and notifying you
                about pertinent changes to our services.
              </li>
            </ul>
            <p>
              We will not collect or use personal information for purposes other
              than those described in this privacy policy unless we have obtained
              your prior consent or as otherwise required or authorized by law.
            </p>
          </section>

          <section id="thirdParties" ref={sectionRefs.thirdParties}>
            <h2>How Do We Share Your Information?</h2>
            <p>
              We share your information with our marketing partners and with
              judicial authorities that require information for legal purposes.
              However, we do not share your personal data with unrelated third
              parties.
            </p>
          </section>

          <section id="userRights" ref={sectionRefs.userRights}>
            <h2>Your Rights and Choices</h2>
            <p>
              As described in the Personal Information Protection Act (PIPA),
              users are entitled to request access to their own information. To
              do so, please reach out to us via the contact page.
            </p>
            <h3>Account Information</h3>
            <p>
              Trendcaster.com allows you to create an account. You may delete
              your account at any time by accessing your account information and
              selecting “Delete Account.” If you delete your account, your
              personal information will be deleted after 30 days (in case you
              want to revert the deletion request).
            </p>
          </section>

          <section id="contactUs" ref={sectionRefs.contactUs}>
            <h2>Contact Information</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy, our
              website, or our services, please contact us at
              contact@trendcaster.ca.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
