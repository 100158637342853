// SINGLE BAR CHART IMPLEMENTATION
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, LineController, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import FilterArticlesByDate  from './filterByDate';
import * as AdapterDateFns from 'chartjs-adapter-date-fns';
import { articleIndexMapping } from './articleIndexMapping';
Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, AdapterDateFns, TimeScale);

const PublisherChart = ({ articles, className }) => {
    //State variable to keep track of number of publishers to display (default 10)
    const [numPublishers, setNumPublishers] = useState(10);

    //State variable to keep track of articles (so filtering can be done)
    const [filteredArticles, setFilteredArticles] = useState([...articles]);
  
    //UseEffect to update filtered articles whenever new articles are passed in!
    useEffect(() => {
      setFilteredArticles(articles);
    },[articles]);

    //Function to update the filtered articles
    const filterArticles = (newFilteredArticles) =>
    {
      setFilteredArticles(newFilteredArticles);
    }

    const handleNumPubSelect = (newNumPublishers) => {
      setNumPublishers(newNumPublishers);
    }

    // Calculate the occurrences of each name.
    //Create Map (similar to Python dict)
    const occurrencesMap = new Map();
    //Iterate through each article in the array of articles
    filteredArticles.forEach(article => {
      const name = article[articleIndexMapping["publisher"]].toLowerCase(); // Index of the element showing the publisher name
      //If dictionary already has publisher as key, increase its corresponding count!
      if (occurrencesMap.has(name)) {
        occurrencesMap.set(name, occurrencesMap.get(name) + 1);
      }
      //If dictionary, does not have publisher as key, set it and default count to 1
      else {
        occurrencesMap.set(name, 1);
      }
    });
  
    // Sort the publishers by occurrences in descending order
    const sortedNames = Array.from(occurrencesMap.keys()).sort((a, b) => {
      return occurrencesMap.get(b) - occurrencesMap.get(a);
    });
  
    //Get the top publishers and their occurrences based on user selection
    const topNames = sortedNames.slice(0, numPublishers);
    const labels = topNames;
    const occurrences = topNames.map(name => occurrencesMap.get(name));
  
    // Prepare the data for the chart
    const chartData = {
      //Name of publishers
      labels: labels,
  
      //List of occurrences corresponding to names of publishers
      datasets: [
        {
          label: 'Articles',
          data: occurrences,
          backgroundColor: 'blue',
        },
      ],
    };
  
    // Customize chart options
    const chartOptions = {
      indexAxis: 'y', //Set indexAxis to y to make it a horizontal bar chart!
      scales: {
        y: {
          title: {
            //Don't display y axis (publishers)
            display: false,
            text: 'Publishers',
          },
        },
      x: {
        beginAtZero: true,
        //Ticks allows to customize behavior / appearance of axis ticks
        ticks: {
          //Callback allows to customize apppearance of each axis tick
          //Receives tick value as argument
          callback: (value) => {
            if (Number.isInteger(value)) {
              return value; //Display only whole numbers on the x-axis
            }
            return ''; //Hide non-integer values (decimals)
          },
        },
      }
      },
    };
  
    return (
      <div className={className}>
        <h2>Top {numPublishers} Publishers</h2>
        <Bar data={chartData} options={chartOptions} />
        <FilterArticlesByDate articles={articles} handleArticleUpdate={filterArticles} />
        {/* Drop down to allow selecting number of publishers */}
        <select value={numPublishers} onChange={(event) => handleNumPubSelect(parseInt(event.target.value))}>
            {/* Array(15) creates undefined elements
            [...Array(15)] used to convert empty slots into actual elements so map will work!*/}
            {[...Array(15)].map((_, index) => (
            // Give each element a unique key!
              <option key={index + 1} value={index + 1}>
                Top {index + 1} {index === 0 ? "publisher" : "publishers"}
              </option>
            ))}
          </select>
      </div>
    );
  };
  export default PublisherChart;



//Stacked bar chart implementation
// import React, {useState} from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
// import * as AdapterDateFns from 'chartjs-adapter-date-fns';

// Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, AdapterDateFns, TimeScale);

// const possibleColors = [
//   'rgba(255, 99, 132, 1)',   // Red
//   'rgba(54, 162, 235, 1)',   // Blue
//   'rgba(255, 206, 86, 1)',   // Yellow
//   'rgba(75, 192, 192, 1)',   // Green
//   'rgba(153, 102, 255, 1)',  // Purple
//   'rgba(255, 159, 64, 1)',   // Orange
//   'rgba(255, 0, 0, 1)',      // Dark Red
//   'rgba(0, 0, 255, 1)',      // Dark Blue
//   'rgba(0, 255, 0, 1)',      // Dark Green
//   'rgba(128, 0, 128, 1)',    // Dark Purple
//   'rgba(255, 140, 0, 1)',    // Dark Orange
//   'rgba(0, 128, 128, 1)',    // Teal
//   'rgba(128, 128, 0, 1)',    // Olive
//   'rgba(128, 0, 0, 1)',      // Maroon
//   'rgba(0, 0, 128, 1)',      // Navy
//   'rgba(219, 64, 82, 1)',    // Crimson
//   'rgba(0, 166, 147, 1)',    // Turquoise
//   'rgba(255, 204, 92, 1)',   // Gold
//   'rgba(162, 113, 194, 1)',  // Lavender
//   'rgba(242, 119, 122, 1)',  // Salmon
//   'rgba(255, 165, 0, 1)',    // Orange
//   'rgba(0, 128, 0, 1)',      // Green
//   'rgba(70, 130, 180, 1)',   // Steel Blue
//   'rgba(128, 0, 128, 1)',    // Purple
//   'rgba(255, 0, 255, 1)',    // Magenta
//   'rgba(0, 128, 128, 1)',    // Teal
//   'rgba(255, 69, 0, 1)',     // Orange-Red
//   'rgba(0, 255, 0, 1)',      // Lime
//   'rgba(128, 128, 128, 1)',  // Gray
//   'rgba(218, 112, 214, 1)',  // Orchid
//   'rgba(95, 158, 160, 1)',   // Cadet Blue
// ];

// const PublisherChart = ({ articles, className }) => {

//   const [colorIndex, setColorIndex] = useState(0);

//   //Function to return new color for each data set
//   const returnColor = () => {
//     const colorToReturn = possibleColors[colorIndex];
//     console.log("Return color",colorToReturn);
//     const newColorIndex = colorIndex + 1;
//     //Reset index if it's equal to length of possible colors
//     if (newColorIndex >= possibleColors.length) {
//       newColorIndex = 0;
//     }
//     setColorIndex(newColorIndex);
//     return colorToReturn;
//   }

//   // Create a map to store the occurrences of each publisher for each day
//   const occurrencesMap = new Map();

//   //Keep track of the days added!
//   let daysAdded = 0;

//   // Iterate through each article in the array of articles
//   for (let i = 0; i < articles.length; i++) {
//     const article = articles[i];
//     const day = article[6].split('T')[0]; // 6 corresponds to the index of the date
//     const publisher = article[4].toLowerCase(); // Index of the element showing the publisher name
  
//     if (occurrencesMap.has(day)) {
//       const publishers = occurrencesMap.get(day);
//       const publisherObj = publishers.find((p) => p.name === publisher);
//       if (publisherObj) {
//         publisherObj.count += 1; // Increment the count if the publisher exists
//       } else {
//         publishers.push({ name: publisher, count: 1 }); // Add a new publisher object if it doesn't exist
//       }
//     } else {
//       daysAdded += 1;
//       if (daysAdded > 5) {
//         break;
//       }
//       occurrencesMap.set(day, [{ name: publisher, count: 1 }]);
//     }
//   }
//   occurrencesMap.forEach((publishers, day) => {
//     publishers.sort((a, b) => b.count - a.count); // Sort publishers by count in descending order
//     occurrencesMap.set(day, publishers.slice(0, 5)); // Keep only the top 5 publishers
//   });

//   //Only keep the last 5 occurrences
//   if (occurrencesMap.size > 5) {
//     const keysToDelete = Array.from(occurrencesMap.keys()).slice(0, occurrencesMap.size - 5);
//     keysToDelete.forEach(key => occurrencesMap.delete(key));
//   }

//   // Get the unique publishers
//   const publishersSet = new Set();
//   occurrencesMap.forEach(publishers => {
//     publishers.forEach((publisher) => {
//       publishersSet.add(publisher.name);
//     });
//   });

//   // Sort the publishers alphabetically
//   const sortedPublishers = Array.from(publishersSet).sort();

//   // Prepare the data for the chart
//   const labels = Array.from(occurrencesMap.keys());
//   const datasets = sortedPublishers.map((publisher) => {
//     const data = labels.map((day) => {
//       const publishers = occurrencesMap.get(day);
//       const countObj = publishers.find((p) => p.name === publisher);
//       const count = countObj ? countObj.count : 0;
//       return count;
//     });
  
//     return {
//       label: publisher,
//       data: data,
//       backgroundColor: 'blue', // Set a background color for each publisher
//       stack: 'stack'
//     };
//   });


//   // Customize chart options
//   const chartData = {
//     labels: labels,
//     datasets: datasets,
//   };

//   const chartOptions = {
//     scales: {
//       x: {
//         type: 'time',
//         adapters: {
//           date: AdapterDateFns,
//         },
//         time: {
//           unit: 'day',
//         },
//         title: {
//           display: true,
//           text: 'Date',
//         },
//       },
//       y: {
//         beginAtZero: true,
//         stacked: true, // Enable stacked bars
//         title: {
//           display: true,
//           text: 'Occurrences',
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         display: true,
//         position: 'bottom',
//       },
//     },
//   };

//   return (
//     <div className={className}>
//       <h2>Stacked Bar Chart - Publishers</h2>
//       <Bar data={chartData} options={chartOptions} />
//     </div>
//   );
// };

// export default PublisherChart;

