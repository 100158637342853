import React, { useState, useEffect, useRef } from 'react';
import Header from "./header.jsx";
import Placeholder from "../assets/images/placeholder.png";
import "./heroSection.css"; // Assuming you have a CSS file for styling this component
import Captcha from "./captcha"

function HeroSection() {

  const [email, setEmail] = useState(''); //For keeping track of user's email
  const [emailMessage, setEmailMessage] = useState(); //For keeping track if email submit was success or not
  const [captchaToken, setCaptchaToken] = useState();
  const handleCaptchaVerify = (token) =>{
      setCaptchaToken(token);
  };

  const submitEmail = async (e, source) => {
    setEmail('');
    e.preventDefault();
    const emailInfo={
        "email":email,
        "source":source
    };
    console.log(`${process.env.REACT_APP_LANDING_PAGE_BACKEND}`);
    //POST Request
    const res = await fetch(`${process.env.REACT_APP_LANDING_PAGE_BACKEND}`, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    'x-functions-key': `${process.env.REACT_APP_LANDING_PAGE_KEY}`
    },
    body: JSON.stringify(emailInfo),
    });

    //If the response is success, let user know their email was saved successfully!
    if (res.ok) {
        setEmailMessage("*Email Submitted Successfully!")
    }
    //If response is not a success, report error and wipe password field
    else {
    setEmailMessage("*Error adding email! Please try again or try with a different email.")
    setEmail('');
    }
}

  return (
  
  
  <section className="hero-section">
                  <Header />

      <div className="hero-content-container">
        <div className="hero-content">
          <h1 className="hero-title">
            <span>
              International Media Aggregation, <br />
              Extraction, Analytics, and Insights
            </span>
          </h1>
          <p className="hero-subtitle">
            Stay ahead with real-time insights, tailored filters, and unbiased
            analysis. Explore trends, enhance perspective, and personalize your
            news experience—all in one powerful platform.
          </p>
<form className="email-form" onSubmit={(e)=>submitEmail(e,"landingPageTop")}>
        {/* Honeypot field to catch bots that auto fill fields */}
        <input type="text" name="verifier" Style={"display:none"} value="" /> 
        <input
          type="email"
          placeholder="Enter your email"
          className="email-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          maxlength="100"
        />
        {/* <Captcha onVerify={handleCaptchaVerify} /> */}
        <button type="submit" className="cta-button-main">Get Started</button></form>
        {emailMessage && <div style={{color: "red"}}>{emailMessage}</div>}
        </div>
        <div className="hero-animation">
  <img src={Placeholder} alt="Dashboard Animation" />
</div>
      </div>

      <div className="hero-navigation-button-container">
        <button
          className="hero-navigation-button"
          onClick={() =>
            document
              .getElementById("data-insights-section")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          <div className="hero-navigation-subtitle">Our Technology</div>
          <div className="hero-navigation-icon">...</div>
        </button>
      </div>
    </section>
  );
}

export default HeroSection;
