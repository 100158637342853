import React from "react";
import { Link } from "react-router-dom";
import "./AlgorithmSection.css"; // Optional: Include the CSS for styling

function AlgorithmSection() {
  return (
    <section id="algorithm-section" className="algorithm-section">
      <div className="algo-header">
        <h2 className="algo-title">Comprehensive Data Extraction and Analysis</h2>
      </div>

      <div className="custom-grid">
        <div className="grid-item small box-1">
          <img src="/metadata.png" alt="Metadata Image" />
        </div>
        <div className="grid-item small box-2">
          <img src="/scoring.png" alt="Scoring Image" />
        </div>
        <div className="grid-item large box-3">
          <img src="/samplearticle.png" alt="Entities Image" />
        </div>
        <div className="grid-item small box-4">
          <img src="/keywords.png" alt="Keywords Image" />
        </div>
        <div className="grid-item small box-5">
          <img src="/entities.png" alt="Article describing summary of intel's chip failure" />
        </div>
      </div>

      <div className="algo-footer">
    <p>Built on a robust processing pipeline, our platform extracts key metadata, identifies relevant entities,
       analyzes sentiment, and scores accuracy, bias, and readability for every article. Trust
        in the insights you receive, powered by machine learning algorithms designed to deliver transparency and actionable results.</p>
      <Link to="/login" className="algo-cta-button">
  Get Started
</Link>
  </div>

  <div className="analysis-navigation-button-container">
        <button
          className="analysis-navigation-button"
          onClick={() =>
            document
              .getElementById("presentation-section")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          <div className="analysis-navigation-subtitle">Visualization</div>
          <div className="analysis-navigation-icon">...</div>
        </button>
      </div>
    </section>
  );
}

export default AlgorithmSection;
