import React, {useState} from 'react';
import InfoIcon from '@mui/icons-material/Info';
import './informationHelper.css'


//Component for information helper!
function InformationHelper({ helpMessage }) {
    return (
      <span className="information-helper">
        <InfoIcon />
        <span className="message">
          <span className="arrow"></span>
          {helpMessage}
        </span>
      </span>
    );
  }

export default InformationHelper;