import React, { useState } from "react";
import "./FAQSection.css";

function FAQSection() {
  const [openFaqs, setOpenFaqs] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("General");

  const toggleFaq = (index) => {
    setOpenFaqs((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((i) => i !== index); // Close if already open
      } else {
        return [...prevState, index]; // Open the FAQ
      }
    });
  };

  const faqCategories = {
    General: [
      { question: "What Does This Tool Do?", answer: "This tool aggregates and analyzes media data to provide insights, trends, and reports for various industries." },
      { question: "What are your main categories of industry?", answer: "We primarily focus on collecting articles from the technology, science, economy, business, and government industries." },
      { question: "What type of sources does this tool analyze?", answer: "We analyze various online news websites." },
      { question: "Are There Limits On How Much Data I Can Analyze?", answer: "Yes, we are currently limited users to 6 pulls every eight hours until we reestimate resource allocation." },
      { question: "Is This Tool Really Free to Use?", answer: "Yes, this tool is absolutely free to use during the testing period." },
      { question: "How do I get started?", answer: "Request an account, tell us about yourself, then recieve your credentials to login!" },
      { question: "Can I access the tool on mobile devices?", answer: "Yes, our platform is fully responsive and can be accessed on smartphones and tablets." },
    ],
    Analysis: [
      { question: "How is sentiment analysis performed?", answer: "We feed text to an LLM that is specialzed in word processing to evaluate sentiment in media content." },
      { question: "What is entity tracking?", answer: "Entity tracking identifies and monitors mentions of people, organizations, and places in the analyzed content." },
      { question: "Can I customize analysis parameters?", answer: "Yes, you can adjust filters such as date range, industries, and regions." },
      { question: "How are trends visualized?", answer: "Trends are displayed using a variety of data visualization tools. Please refer to the visual section above for a sample." },
      { question: "Can I track historical trends?", answer: "Yes, you can view historical trends and compare them over different time periods." },
    ],
    Data: [
      { question: "What type of media do you analyze?", answer: "We currently analyze online news articles." },
      { question: "Can you scrape my custom source?", answer: "Yes, please reach out using our contact page to inqurie more about custom sourcing." },
      { question: "What file formats can I export?", answer: "Exports are available in CSV format." },
      { question: "How often is the database updated?", answer: "Our database is updated every eight hours as new content is published." },
      { question: "How is data quality ensured?", answer: "Data is validated through automated checks and manual reviews for accuracy and consistency." },
    ],
  };

  return (
    <section id="faq-section" className="faq-section">
      <div className="faq-header">
        <h2 className="faq-title">Frequently Asked Questions</h2>
        <div className="faq-categories">
          {Object.keys(faqCategories).map((category) => (
            <button
              key={category}
              className={`faq-category-button ${currentCategory === category ? "active" : ""}`}
              onClick={() => {
                setCurrentCategory(category);
                setOpenFaqs([]); // Reset open FAQs when switching categories
              }}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      <div className="faq-grid">
        {faqCategories[currentCategory].map((item, index) => (
          <div
            key={index}
            className={`faq-item ${openFaqs.includes(index) ? "open" : ""}`}
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">{item.question}</div>
            <div className="faq-answer">{item.answer}</div>
          </div>
        ))}
      </div>
      <button
  className="faq-navigation-button"
  onClick={() =>
    document
      .getElementById("CTA-section")
      .scrollIntoView({ behavior: "smooth" })
  }
>
  <div className="faq-navigation-subtitle">Getting Started</div>
  <div className="faq-navigation-icon">...</div>
</button>

    </section>
  );
}

export default FAQSection;
