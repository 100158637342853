import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './toc.css';

export default function TermsAndConditions() {
    useEffect(() => {
        const handleScroll = () => {
            // Optional: Add any scroll-specific behavior if needed
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="terms-container">
            {/* Header with Logo */}
            <header className="header">
                <Link to="/" className="logo">TRENDCASTER</Link>
            </header>

            {/* Main Content */}
            <div className="terms-content">
                <div className="terms-sections">
                    <section id="introduction">
                        <p>Updated Sep 2, 2023 </p>
                        <h2>Introduction</h2>
                        <p>Welcome, and thank you for choosing to use Trendcaster for your analytical needs. These Terms of Use (“Terms”) apply to your use of Trendcaster’s websites, products, and services. Please read these Terms carefully. These Terms contain important information about your relationship with Trendcaster, including mandatory arbitration of disputes between us, instead of class action or jury trials; by using Trendcaster, you agree to be bound by them.
                    </p><br></br>
                    <p>Trendcaster analyzes and news articles to create a consolidated, personalized dashboard for its users, including presenting content on our website and apps that Trendcaster believes you may be interested in based on the content that you have read, clicked on, or otherwise interacted with. Please see our privacy policy for more information on how we collect and use your information. </p></section>

                    <section id="thirdPartySites">
                        <h2>Third Party Sites and Products</h2>
                        <p>
                        We assume no liability for anything that you or anyone else publishes through Trendcaster. Trendcaster also contains links to websites, services, content, and advertisements for third party sites and products that we don’t own or control (collectively, “Third Party Sites”). Trendcaster provides links to these Third Party Sites only as a convenience and does not review, approve, monitor, endorse, warrant, or make any representation with respect to Third Party Sites, including as to any content or accessibility of content on Third Party Sites, and we assume no responsibility for such Third Party Sites, third party content, or products purchased through Third Party Sites. You agree that you use all third Party Sites at your own risk. When you link to a Third Party Site, the applicable service provider’s terms and policies, including privacy and data gathering practices govern. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction with any Third Party Site. 
                        </p>
                    </section>

                    <section id="privacy">
                        <h2>Privacy</h2>
                        <p>
                            Our Privacy Policy and Cookie Policy explains our collection and use of information on the
                            service, including information you may consider personal. You agree that we may provide
                            messages and notices to you within the Trendcaster services or by sending such notices to
                            the contact information you have provided to us, such as your email address. You will keep
                            your contact information up to date. You may control the messages you receive in your
                            settings.
                        </p>
                    </section>

                    <section id="security">
                        <h2>Security</h2>
                        <p>
                            We take the security of Trendcaster very seriously. We use reasonable practices to protect
                            the security of your account and data associated with it, but we can’t guarantee that
                            someone won’t be able to defeat our security measures. Please let us know immediately if you
                            discover any compromise of your account. We are always on the lookout for security loopholes
                            in Trendcaster. If you discover a vulnerability in our services, we would be grateful for
                            your report and encourage you to let us know immediately. To report a vulnerability, please
                            email contact@trendcaster.com.
                        </p>
                    </section>

                    <section id="noKids">
                        <h2>No Kids</h2>
                        <p>Trendcaster is only for people 12 years old and older. If we learn that a child under 12 is using our service, we will terminate the child’s account. </p>
                    </section>

                    <section id="copyright">
                        <h2>Copyright</h2>
                        <p>Trendcaster is protected by Canadian Copyright Law and Canada's IP policy.</p>
                    </section>

                    <section id="changesToService">
                        <h2>Changes to our Service</h2>
                        <p>
                            Trendcaster is a dynamic product, always evolving, and getting better. As a result, we may
                            change, eliminate, or restrict access to our products or any part of our service at any
                            time, for any reason, with or without advance notice. And we may do so with respect to one,
                            some or all of our users.
                        </p>
                    </section>

                    <section id="disclaimers">
                        <h2>Disclaimers</h2>
                        <p>
                            Trendcaster is provided “as is” without any warranties, express or implied. Trendcaster is
                            not responsible for any actions taken by the user using data gathered. Trendcaster disclaims
                            all warranties and conditions of merchantability fitness for a particular purpose and
                            non-infringement.
                        </p>
                    </section>

                    <section id="limitationOfLiability">
                        <h2>Limitation of Liability</h2>
                        <p>
                            To the fullest extent allowed by law, Trendcaster shall not be liable for any indirect,
                            incidental, special, consequential, or punitive damages, or any loss of profits or revenues,
                            whether incurred directly or indirectly, or any loss of data, use, goodwill, or other
                            intangible losses resulting from (1) your access to, use of, inability to access or inability
                            to use Trendcaster; (2)any third party conduct or content on Trendcaster, including any
                            defamatory, offensive or illegal conduct of third parties; or (3) any unauthorized access,
                            use or alteration of your content. The maximum liability of Trendcaster to you is $100.
                        </p>
                    </section>

                    <section id="arbitration">
                        <h2>Arbitration</h2>
                        <p>
                            If you have a dispute with Trendcaster for any reason, we’d like the opportunity to work out
                            our differences informally. We strongly encourage you to visit our support page, and we’ll do
                            our best to resolve the dispute in good faith. If we aren't able to solve the problem
                            through an informal dispute, we both agree that we will resolve any claims arising from
                            these Terms or the Trendcaster service by final and binding arbitration as dictated by the
                            Commercial Arbitration Act. The arbitration will take place in Victoria, British Columbia,
                            or another location we both agree upon. Either of us may file a lawsuit in court solely for
                            injunctive relief against the other to stop misuse of Trendcaster or intellectual property
                            infringement without first going through arbitration. You agree to resolve any dispute you
                            have with us on an individual basis in arbitration, and not as a class arbitration, class
                            action or consolidated proceeding of any kind.
                        </p>
                    </section>

                    <section id="governingLaw">
                        <h2>Governing Law and Jurisdiction</h2>
                        <p>
                            These Terms will be governed by the laws of the Province of British Columbia, except for its
                            conflict of laws principles. For claims that aren’t subject to arbitration, we each agree
                            that any such claims shall be litigated exclusively in a state court, or in the Canadian Territorial/ provincial court.
                        </p>
                    </section>

                    <section id="entireAgreement">
                        <h2>Entire Agreement</h2>
                        <p>
                            These Terms, our Privacy Policy, Copyright Statement, and Cookie Policy constitute the
                            entire agreement between you and Trendcaster. If any provision of these Terms is found to be
                            unenforceable, the remaining provisions will continue to be in full force and effect. Our
                            failure to enforce or exercise any right or provision of these Terms shall not operate as a
                            waiver of such right or provision.
                        </p>
                    </section>

                    <section id="modification">
                        <h2>Modification</h2>
                        <p>
                            These Terms may be modified periodically. The date of the most recent revision will always
                            be located on this page, and older versions may be given upon request. If we make changes
                            that we believe will substantially alter your rights, we will notify you by posting a notice
                            on our website. You agree to accept any changes or revision to these Terms by continuing to
                            use Trendcaster.
                        </p>
                    </section>

                    <section id="contact">
                        <h2>Contact</h2>
                        <p>
                            We welcome all questions, concerns and feedback you might have about these terms. If you
                            have suggestions for us, please contact us through the contact page.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
}
