import React from 'react';
import './selectionBox.css'

function SelectionBox({className, currentSelection, coordinates, lastUpdated})
{
    return <>
    <div className={className}>
        <div className="selectionContainer">
            <p className="item">Current Selection: {currentSelection}</p>
            <p className="item">Coordinates: {coordinates}</p>
            <p className="item">Last updated: {lastUpdated}</p>
        </div>
    </div>
    </>
}

SelectionBox.defaultProps = {
    currentSelection: "None",
    coordinates: "None",
    lastUpdated: "2 Hours Ago"
};
export default SelectionBox