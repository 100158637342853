import React, { useState } from "react";
import sentimentImage from "../assets/images/sentimentDash.png";
import entityImage from "../assets/images/entityAnalysisDash.png";
import keywordImage from "../assets/images/entityOverview.png";
import sourceImage from "../assets/images/sourceDash.png";
import { Link } from "react-router-dom";
import "./PresentationSection.css";

function PresentationSection() {
  const [currentImage, setCurrentImage] = useState("sentiment");

  const imageMap = {
    sentiment: sentimentImage,
    entity: entityImage,
    keyword: keywordImage,
    source: sourceImage,
  };

  return (
    <section id="presentation-section" className="presentation-section">
      <div className="presentation-container">
        {/* Left Side - Text and Buttons */}
        <div className="presentation-left">
          <div className="presentation-text">
            <h2 className="presentation-title">Visualized Insights</h2>
            <p className="presentation-description">
              Seamlessly consolidate complex data into clear, interactive visuals
              for smarter decisions and deeper understanding.
            </p>
            <div className="navigation-buttons">
              <button
                className={`navigation-button ${
                  currentImage === "sentiment" ? "active" : ""
                }`}
                onClick={() => setCurrentImage("sentiment")}
              >
                Sentiment Analysis
              </button>
              <button
                className={`navigation-button ${
                  currentImage === "entity" ? "active" : ""
                }`}
                onClick={() => setCurrentImage("entity")}
              >
                Entity Tracking
              </button>
              <button
                className={`navigation-button ${
                  currentImage === "keyword" ? "active" : ""
                }`}
                onClick={() => setCurrentImage("keyword")}
              >
                Keyword Tracking
              </button>
              <button
                className={`navigation-button ${
                  currentImage === "source" ? "active" : ""
                }`}
                onClick={() => setCurrentImage("source")}
              >
                Source Transparency
              </button>
            </div>
          </div>
        </div>

        {/* Right Side - Dashboard Image */}
        <div className="presentation-right">
          <div className="dashboard-container">
            <img
              src={imageMap[currentImage]}
              alt={`${currentImage} dashboard`}
              className="dashboard-image"
            />
            <div className="image-tracker">
              <div
                className={`tracker-block ${
                  currentImage === "sentiment" ? "active" : ""
                }`}
              ></div>
              <div
                className={`tracker-block ${
                  currentImage === "entity" ? "active" : ""
                }`}
              ></div>
              <div
                className={`tracker-block ${
                  currentImage === "keyword" ? "active" : ""
                }`}
              ></div>
              <div
                className={`tracker-block ${
                  currentImage === "source" ? "active" : ""
                }`}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section - CTA and Navigation Button */}
      <div className="presentation-bottom">
        <div className="presentation-cta">
          <Link to="/login" className="cta-button-visual">Get Started</Link>
        </div>
        <div className="navigation-button-container-visual">
          <button
            className="navigation-button-faq"
            onClick={() =>
              document
                .getElementById("faq-section")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <div className="FAQ-navigation-subtitle">FAQ</div>
            <div className="navigation-icon">...</div>
          </button>
        </div>
      </div>
    </section>
  );
}

export default PresentationSection;
