import React, { createContext, useContext, useState, useEffect } from 'react';

//Context for managing token state across entire app 
const AccessTokenContext = createContext();

//Context for managing various state across entire app
const GlobalArticleContext = createContext();

export function GlobalArticleStates({children}) {
    const [likedArticles,setLikedArticles] = useState(["-1"]);

    async function fetchLikedArticles({accessToken}) {
        console.log("ACCESS TOKEN IS: ",accessToken);
        try {
            const returnedLikedArticles = await fetch(`${process.env.REACT_APP_DJANGO_BACKEND_HOST}/api/returnLikedArticles`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const parsedData= await returnedLikedArticles.json();
            console.log("RESPONSE: ",parsedData);
            setLikedArticles(parsedData);
            return parsedData;
        }
        catch (error) {
            console.log("ERROR: ", error);
            return "ERROR";
        }
    }
    return (
        <GlobalArticleContext.Provider value={{likedArticles, setLikedArticles, fetchLikedArticles }}>
          {children}
        </GlobalArticleContext.Provider>
      );
}


export function AccessTokenProvider({ children }) {
    //State variable to keep track of current page (so if resized, or zoomed stays on current page!)
    const [currentPage, setCurrentPage] = useState(0);
     //Child function to return access token
     const getAccessToken = () => {
        const tokenString = sessionStorage.getItem('access_token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    };

    //Function for handling when the user resizes the page to keep them on current choice!
    const handleResize = () => {
        console.log("KEEPING YOU ON PAGE: ",currentPage);

        window.scrollTo(
            {
              top: currentPage * window.innerHeight
            }
          );
    };

    useEffect(() => {
        // Event listener to handle resizing (to ensure current page is displayed when resized)
        window.addEventListener('resize', handleResize);
      
        return () => {
          // Clean up the event listener when the component unmounts
          window.removeEventListener('resize', handleResize);
        };
      }, [currentPage]);

    //Child function to return refresh token
    const getRefreshToken = () => {
        const tokenString = sessionStorage.getItem('refresh_token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    };

    //State variables to store access and refresh tokens (to trigger re-render when changed)
    const [accessToken, setAccessToken] = useState(getAccessToken());
    const [refreshToken, setRefreshToken] = useState(getRefreshToken());
    console.log("ACCESS TOKEN IS: ",accessToken);
    //Child function to set the access token
    const saveAccessToken = (newAccessToken) => {
        console.log("ACCESS TOKEN SAVED: ", newAccessToken)
        sessionStorage.setItem('access_token',JSON.stringify(newAccessToken));
        setAccessToken(newAccessToken);
    };

    //Child function to set the refresh token
    const saveRefreshToken = (newRefreshToken) => {
        console.log("REFRESH TOKEN SAVED: ", newRefreshToken);
        sessionStorage.setItem('refresh_token',JSON.stringify(newRefreshToken));
        setRefreshToken(newRefreshToken);
    };

    //Child to expire token and logout
    //POST Request
    const expireToken = async () => {
        try {
            console.log("1", refreshToken);
            if (refreshToken) {
                //Send refresh token to backend to expire (expiring refresh will expire access too)
                const res = await fetch(`${process.env.REACT_APP_DJANGO_BACKEND_HOST}/api/logout`, {
                    method: 'POST',
                    headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({refreshToken:refreshToken}),
                 });
            
                //If successful remove token from session storage
                if (res.ok)
                {
                    sessionStorage.removeItem('access_token');
                    sessionStorage.removeItem('refresh_token');
                    saveAccessToken(null);
                    saveRefreshToken(null);
                }
                //If not successful log error
                else
                {
                    console.log("Logout Failed!");
                }

            }
        }
        //If error in code, log error
        catch (error) {
            console.log("Logout error", error);
        }
    }

    async function makeRequest(method, url, data={})
    {
        const requestURL=`${process.env.REACT_APP_DJANGO_BACKEND_HOST}/${url}`;
        try {
            // Define headers with the access token
            const headers = {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            };

            //Define other options for request
            const requestOptions= {
                method,
                headers
            };
            //If method isn't get, add a body!
            if (method !== 'GET') {
                requestOptions.body = JSON.stringify(data);
            }
            console.log("REQUEST OPTIONS: ",requestOptions);
            const response = await fetch(requestURL, requestOptions);
            //If good to go, return resonse data!
            if (response.status==200)
            {
                //If response has no data just set it to null!
                let responseData = null;
                try {
                    responseData = await response.json();
                }
                catch{
                    responseData = null;
                }
                return {data: responseData, status: response.status};
            }
            //If status is 401 (unauthorized), refresh access token!
            else if (response.status==401)
            {
                const refreshResponse = await fetch(`${process.env.REACT_APP_DJANGO_BACKEND_HOST}/api/token/refresh`, {
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${refreshToken}`,
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ refresh: refreshToken }),
                  });
                  console.log("REFRESH RESPONSE: ",refreshResponse);
                  if (!refreshResponse.ok) {
                    throw new Error('Token refresh failed');
                  }
            
                  // Update the access & refresh token with the new token
                  const newTokens = await refreshResponse.json();
    
                  // Update accessToken global context with new access and refresh token
                  saveAccessToken(newTokens.access);
                  saveRefreshToken(newTokens.refresh);
                  
                  console.log("NEW ACCESS TOKEN: ", accessToken);
                  
                  // Retry the original request with the new access token
                    // Define headers with the access token
                    const headers = {
                        Authorization: `Bearer ${newTokens.access}`,
                        'Content-Type': 'application/json',
                    };
        
                    //Define other options for request
                    const requestOptions= {
                        method,
                        headers
                    };
                    //If method isn't get, add a body!
                    if (method !== 'GET') {
                        requestOptions.body = JSON.stringify(data);
                    }
                    const retryResponse = await fetch(requestURL, requestOptions);
                    
                    if (retryResponse.ok)
                    {
                        //If response has no data just set it to null!
                        let retryResponseData = null;
                        try {
                            retryResponseData = await retryResponse.json();
                        }
                        catch{
                            retryResponseData = null;
                        }
                        return {data:retryResponseData, status:retryResponse.status}
                    }
                    //If not good response, throw error
                    throw new Error("Error receiving response!")
            }
            //If different status throw an error
            else {
                throw new Error("Error receiving response!");
            }
          } catch (error) {
            console.error('Error making request:', error);
            return {error:error}
          }
    }

    //Centralized function for incrementing user count when viewing an article
    const handleViewArticle = async (articleID) => {
        try {
            console.log("ARTICLE ID IS: ",articleID);
            const response = await makeRequest('POST', '/api/addViewedArticle', {"articleID":articleID});
            if (!response.error)
            {
                console.log("ADDED VIEWED ARTICLE!");
            }
            //Handle error
            else {console.log("ERROR");}
        } catch (error) {
            console.log("ERROR");
        }
    };

  return (
    <AccessTokenContext.Provider value={{ accessToken, refreshToken, saveAccessToken, saveRefreshToken, expireToken, makeRequest,
     handleViewArticle, handleResize, setCurrentPage}}>
      {children}
    </AccessTokenContext.Provider>
  );
};


export function useAccessToken() {
  return useContext(AccessTokenContext);
}

export function useArticleState() {
    return useContext(GlobalArticleContext)
}