import React from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import "./header.css"; // Import the associated CSS file

const Header = () => {
  return (
    <div className="header">
      <Link to="/landingPage" className="logo">TRENDCASTER</Link>
      <nav className="navigation">
        <Link to="/features" className="nav-link">Features</Link>
        <Link to="/contact" className="nav-link">Contact</Link>
        <Link to="/support" className="nav-link">Support</Link>
        <Link to="/login" className="nav-link login-link">Login</Link>
      </nav>
    </div>
  );
};

export default Header;
