import React, { useState, useEffect } from "react";
import "./AnimatedStatBox.css"; // Optional: Include a CSS file for styling


function AnimatedStatBox({ label, value }) {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    const duration = 5000; // Duration of the animation in milliseconds
    const startTime = performance.now();

    function updateNumber(currentTime) {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const currentValue = Math.floor(progress * value);
      setDisplayValue(currentValue);

      if (progress < 1) {
        requestAnimationFrame(updateNumber);
      }
    }

    requestAnimationFrame(updateNumber);
  }, [value]);

  return (
    <div className="stat-box">
      <div className="stat-number">{displayValue.toLocaleString()}+</div>
      <div className="stat-label">{label}</div>
    </div>
  );
}

export default AnimatedStatBox;
