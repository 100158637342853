import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart, LineController, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import * as AdapterDateFns from 'chartjs-adapter-date-fns';
import { articleIndexMapping } from './articleIndexMapping';
Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, AdapterDateFns, TimeScale);

const SentimentGraph = ({ articles }) => {

  //Map sentiment labels to scores
  const sentimentLabelMapping = {
    "positive":1,
    "neutral":0,
    "negative":-1
  }

  // Calculate average score for each day
  const dailyAverages = {};

  //Go through each article
  articles.forEach(article => {
    //Get the article's date (split on the T)
    const date = article[articleIndexMapping["publishDate"]].split('T')[0]; // 6 corresponds to the index of the date
    //If there are no daily averages for the date, add the date!
    if (!dailyAverages[date]) {
      //Set the default to 0 for total score and count
      dailyAverages[date] = {
        totalScore: 0,
        count: 0,
      };
    }

    //Increase the total score and count for date
    dailyAverages[date].totalScore += sentimentLabelMapping[article[articleIndexMapping["sentScore"]]]; // 7 corresponds to the index of the sentiment score
    dailyAverages[date].count++;
  });
  // Prepare the data for the chart
  const chartData = {
    //Pass dates as the labels for x axis graph
    labels: Object.keys(dailyAverages),
    datasets: [
      {
        label: 'Average Score',
        //Divide each days total score by count to get average
        data: Object.values(dailyAverages).map(avg => avg.totalScore / avg.count),
        fill: false,
        borderColor: 'blue',
        tension: 0.4,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'time',

        //Adapter for formatting and working with date/time
        adapters: {
          date: AdapterDateFns,
        },
        time: {
          tooltipFormat: 'yyyy-MM-dd', // Format for tooltip display
          displayFormats: {
            day: 'yyyy-MM-dd', // Format for x-axis label display
          },
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Average Score',
        },
      },
    },
  };

  return (
    <>
      <h2>Sentiment Analysis Scoring</h2>
      <Line data={chartData} options={chartOptions} style={{height:"100%"}} />
    </>
  );
};
  

const PublisherChart = ({ articles }) => {
  // Calculate the occurrences of each name.
  //Create Map (similar to Python dict)
  const occurrencesMap = new Map();
  //Iterate through each article in the array of articles
  articles.forEach(article => {
    const name = article[articleIndexMapping["publisher"]].toLowerCase(); // Index of the element showing the publisher name
    //If dictionary already has publisher as key, increase its corresponding count!
    if (occurrencesMap.has(name)) {
      occurrencesMap.set(name, occurrencesMap.get(name) + 1);
    }
    //If dictionary, does not have publisher as key, set it and default count to 1
    else {
      occurrencesMap.set(name, 1);
    }
  });

  // Sort the publishers by occurrences in descending order
  const sortedNames = Array.from(occurrencesMap.keys()).sort((a, b) => {
    return occurrencesMap.get(b) - occurrencesMap.get(a);
  });

  //Get the top 10 publishers and their occurrences
  const topNames = sortedNames.slice(0, 10);
  const labels = topNames;
  const occurrences = topNames.map(name => occurrencesMap.get(name));

  // Prepare the data for the chart
  const chartData = {
    //Name of publishers
    labels: labels,

    //List of occurrences corresponding to names of publishers
    datasets: [
      {
        label: 'Occurrences',
        data: occurrences,
        backgroundColor: 'blue',
      },
    ],
  };

  // Customize chart options
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Occurrences',
        },
      },
    },
  };

  return (
    <div>
      <h2>Top 10 Publishers</h2>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

const ParentGraph = ({articles, sentimentClass, publisherClass}) => {  

    console.log(articles);
    //Get only the unique articles (where article ID (the first element) is unique)
    const uniqueArticlesMap = {}; //Dictionary to hold unique article IDs
    const uniqueArticles = []; //Dictionary to hold unique articles
    
    //Iterate through array and get only non-repeating article IDs
    for (let i = 0; i < articles.length; i++) {
      const key = articles[i][articleIndexMapping["id"]];
        
      //If the article ID doesn't already exist in the mapping, push the key and the article!
      if (!uniqueArticlesMap[key]) {
        uniqueArticlesMap[key] = true;
        uniqueArticles.push(articles[i]);
      }
    }
    console.log(uniqueArticles);
    return(
    <>
      <div className={sentimentClass}>
        <SentimentGraph articles={uniqueArticles} />
      </div>
      <div className={publisherClass}>
        <PublisherChart articles={uniqueArticles} />
      </div>
    </>
    );
}

export default ParentGraph;