//Ref: https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
import { useState } from 'react';

//Function to retrieve and save access / refresh tokens
export default function useToken() {

    //Child function to return access token
    const getAccessToken = () => {
        const tokenString = sessionStorage.getItem('access_token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    };

    //Child function to return refresh token
    const getRefreshToken = () => {
        const tokenString = sessionStorage.getItem('refresh_token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    };

    //State variables to store access and refresh tokens (to trigger re-render when changed)
    const [accessToken, setAccessToken] = useState(getAccessToken());
    const [refreshToken, setRefreshToken] = useState(getRefreshToken());

    //Child function to set the access token
    const saveAccessToken = (newAccessToken) => {
        console.log("ACCESS TOKEN SAVED: ", newAccessToken)
        sessionStorage.setItem('access_token',JSON.stringify(newAccessToken));
        setAccessToken(newAccessToken);
    };

    //Child function to set the refresh token
    const saveRefreshToken = (newRefreshToken) => {
        console.log("REFRESH TOKEN SAVED: ", newRefreshToken);
        sessionStorage.setItem('refresh_token',JSON.stringify(newRefreshToken));
        setRefreshToken(newRefreshToken);
    };

    //Child to expire token and logout
    //POST Request
    const expireToken = async () => {
        try {
            //Expire token, if there is a refresh token
            const refreshToken = getRefreshToken();
            console.log("1", refreshToken);
            if (refreshToken) {
                //Send refresh token to backend to expire (expiring refresh will expire access too)
                const res = await fetch(`${process.env.REACT_APP_DJANGO_BACKEND_HOST}/api/logout`, {
                    method: 'POST',
                    headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({refreshToken:refreshToken}),
                 });
            
                //If successful remove token from session storage
                if (res.ok)
                {
                    sessionStorage.removeItem('access_token');
                    sessionStorage.removeItem('refresh_token');
                    setAccessToken(null);
                    setRefreshToken(null);
                }
                //If not successful log error
                else
                {
                    console.log("Logout Failed!");
                }
            }
        }
        //If error in code, log error
        catch (error) {
            console.log("Logout error", error);
        }
    }

    //Return everything as an object so that user can destructure if required
    return {
        "accessToken": accessToken,
        "refreshToken": refreshToken,
        "setAccessToken": saveAccessToken,
        "setRefreshToken": saveRefreshToken,
        "expireToken": expireToken
    };
};