import React, {useState} from "react";
import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import "./contact.css";


const ContactPage = () => {
  const [email, setEmail] = useState(''); //For keeping track of user's email
  const [emailMessage, setEmailMessage] = useState(' '); //For keeping track if email submit was successful or not
  const [bio, setBio] = useState(''); //For keeping track of user's bio
    //------------Method for submitting user info (email and bio/message)
    const submitUserInfo = async (e, source) => {
      setEmail('');
      e.preventDefault();
      const emailInfo={
          "email":email,
          "bio": bio,
          "source":source,
      };
      console.log(`${process.env.REACT_APP_LANDING_PAGE_BACKEND}`);
      //POST Request
      const res = await fetch(`${process.env.REACT_APP_LANDING_PAGE_BACKEND}`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      'x-functions-key': `${process.env.REACT_APP_LANDING_PAGE_KEY}`
      },
      body: JSON.stringify(emailInfo),
      });

      //If the response is success, let user know their email was saved successfully!
      if (res.ok) {
          setEmailMessage("*Info added successfully!")
      }
      //If response is not a success, report error and wipe password field
      else {
      setEmailMessage("*Error adding info! Please try again.")
      setEmail('');
      }
  }
  return (
    <div className="contact-page">

      <Header />
      <section className="contact-section">

      
      <form className="contact-form"onSubmit={(e)=>submitUserInfo(e,"contactPage")}>
        {/* Honeypot field to catch bots that auto fill fields */}
        <input type="text" name="verifier" Style={"display:none"} value="" /> 
        <h1 className="contactPageTitle">Contact Us</h1>
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" 
          required
          maxlength="100"
          onChange={(e)=>setEmail(e.target.value)}
          value={email}
           />

          <label htmlFor="reason">Reason for Request</label>
          <select id="reason" name="reason" required>
            <option value="" disabled selected>Please select a category</option>
            <option value="support">Support</option>
            <option value="inquiry">Inquiry</option>
            <option value="feedback">Feedback</option>
            <option value="feedback">Partnerships</option>
            <option value="other">Other</option>
          </select>

          <label htmlFor="message">Message</label>
          <textarea 
            id="message" name="message" rows="5" required
            maxlength="250"
            onChange={(e) => setBio(e.target.value)}
            value={bio}
            >
          </textarea>
          <button type="submit" className="submit-btn">Submit</button>
          {emailMessage && <div style={{color: "red"}}>{emailMessage}</div>}
          </form>
        
      </section>

      <Footer/>

        </div>

  );
};

export default ContactPage;
