import React, { useState, useEffect, useRef } from 'react';
import './landingPage.css'; // Ensure this file is in the same directory or adjust the path accordingly.
import { Link } from 'react-router-dom';
import Header from "./header.jsx";
import HeroSection from "./heroSection.jsx";
import DataInsightsSection from './DataInsightsSection.jsx';
import AlgorithmSection from "./AlgorithmSection";
import PresentationSection from "./PresentationSection";
import CtaSection from "./CtaSection";
import FAQSection from "./FAQSection";
import Captcha from "./captcha"

export default function HomePage() {
    const [openFaqs, setOpenFaqs] = useState([]); // State to track multiple open FAQs
    const [isScrolled, setIsScrolled] = useState(false);
    const insightsSectionRef = useRef(null);
    const algorithmSectionRef = useRef(null);
    const faqSectionRef = useRef(null);
    const isDragging = useRef(false); // Track whether the user is currently dragging
    const startY = useRef(0); // Store the initial Y position of the mouse
    const scrollStart = useRef(0); // Store the initial scroll position

    useEffect(() => {
        // Ensure the page starts at the top when it loads
        window.scrollTo(0, 0);
        setOpenFaqs([]);

        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        const handleMouseMove = (e) => {
            if (isDragging.current) {
                const diffY = e.clientY - startY.current;
                window.scrollTo(0, scrollStart.current - diffY);
            }
        };

        const handleMouseDown = (e) => {
            isDragging.current = true;
            startY.current = e.clientY;
            scrollStart.current = window.scrollY;
        };

        const handleMouseUp = () => {
            isDragging.current = false;
        };

        // Add scroll and mouse event listeners
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mouseup', handleMouseUp);

        // Cleanup function to remove the event listeners
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    const toggleFaq = (index) => {
        setOpenFaqs(prevState => {
            if (prevState.includes(index)) {
                return prevState.filter(i => i !== index); // Close it if it's already open
            } else {
                return [...prevState, index]; // Open the FAQ
            }
        });
    };

    return (
        <div className="homepage-container">
            <HeroSection />
            <DataInsightsSection />
            <AlgorithmSection />
            <PresentationSection />
            <FAQSection />
            <CtaSection />

            <section>
                <div className="footer-content">
                    <div className="footer-logo">
                        <p className="footer-text-small">© Trendcaster.ca 2024</p>
                    </div>
                    <div className="footer-links">
                        <Link to="/toc">Terms and Conditions</Link>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </div>
                </div>
            </section>
        </div>
    );
}
