import React from "react";
import AnimatedStatBox from "./AnimatedStatBox.jsx";
import { Link } from "react-router-dom";
import "./DataInsightsSection.css"; // Optional: Include CSS for the section

function DataInsightsSection() {
  return (
    <section id="data-insights-section" className="data-insights-section">

      <h2 className="section-title">Data-Centric Insights Through Statistical Strength</h2>
      <div className="stats-grid">
        <AnimatedStatBox label="Unique Countries" value={104} />
        <AnimatedStatBox label="Publisher Sources" value={50} />
        <AnimatedStatBox label="Entities Logged" value={200000} />
        <AnimatedStatBox label="Bias Articles Found" value={801000} />
        <AnimatedStatBox label="Unique Categories" value={126} />
        <AnimatedStatBox label="Articles Scanned" value={1400000} />
        <AnimatedStatBox label="Unique Cities" value={506} />
        <AnimatedStatBox label="Daily Articles" value={20890} />
        <AnimatedStatBox label="Keywords Logged" value={401000} />
      </div>
      <p className="section-description">
        Focus on the data as the core of analysis to uncover actionable patterns, trends, and correlations. Take the
        opportunity to use Trendcaster to identify new opportunities for growth, making smarter, more strategic choices.
      </p>
      <Link to="/login" className="cta-button-data">
  Get Started
</Link>
      <br />
      <button
        className="hero-navigation-button-2"
        onClick={() =>
          document
            .getElementById("algorithm-section")
            .scrollIntoView({ behavior: "smooth" })
        }
      >
        <div className="hero-navigation-subtitle-2">Data Extraction</div>
        <div className="hero-navigation-icon-2">...</div>
      </button>
    </section>
  );
}

export default DataInsightsSection;
