// SINGLE BAR CHART IMPLEMENTATION
import React, { useState, useEffect } from 'react';
import { Bar,Pie } from 'react-chartjs-2';
import { Chart, LineController, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import * as AdapterDateFns from 'chartjs-adapter-date-fns';
Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, AdapterDateFns, TimeScale);

const MetricsChart = ({userMetrics, metricName, metricIndex=0,className="None"}) => {
    //State variable to keep track of top metrics to display to display (default 10)
    const [numMetrics, setnumMetrics] = useState(10);

    //State variable to keep track of userMetrics (so filtering can be done)
    const [filtereduserMetrics, setFiltereduserMetrics] = useState([...userMetrics]);
  
    //UseEffect to update filtered userMetrics whenever new userMetrics are passed in!
    useEffect(() => {
      setFiltereduserMetrics(userMetrics);
    },[userMetrics]);

    //Function to update the filtered userMetrics
    const filteruserMetrics = (newFiltereduserMetrics) =>
    {
      setFiltereduserMetrics(newFiltereduserMetrics);
    }

    const handleNumMetricSelect = (newnumMetrics) => {
      setnumMetrics(newnumMetrics);
    }

    // Calculate the occurrences of each name.
    //Create Map (similar to Python dict)
    const occurrencesMap = new Map();
    //Iterate through each article in the array of userMetrics
    filtereduserMetrics.forEach(userMetric => {
      try {
         const name = userMetric[metricIndex].toLowerCase(); //Metric name index is passed as prop
         //If dictionary already has metric name as key, increase its corresponding count!
      if (occurrencesMap.has(name)) {
        occurrencesMap.set(name, occurrencesMap.get(name) + 1);
      }
      //If dictionary, does not have metric as key, set it and default count to 1
      else {
        occurrencesMap.set(name, 1);
      }
    }
    //Skip current interation if there's error
    catch {
        return;
    }
    });
  
    // Sort the metrics by occurrences in descending order
    const sortedNames = Array.from(occurrencesMap.keys()).sort((a, b) => {
      return occurrencesMap.get(b) - occurrencesMap.get(a);
    });
  
    //Get the top metric and their occurrences based on user selection
    const topNames = sortedNames.slice(0, numMetrics);
    const labels = topNames;
    const occurrences = topNames.map(name => occurrencesMap.get(name));
  
    // Prepare the data for the chart
    const chartData = {
      //Name of metrics
      labels: labels,
  
      //List of occurrences corresponding to names of metrics
      datasets: [
        {
          label: metricName,
          data: occurrences,
          backgroundColor: ['blue', 'red', 'green', 'yellow', 'purple', 'orange']
        },
      ],
    };
  
    // Customize chart options
    const chartOptions = {
      indexAxis: 'y', //Set indexAxis to y to make it a horizontal bar chart!
      scales: {
        y: {
          title: {
            //Don't display y axis (Metric name)
            display: false,
            text: metricName,
          },
        },
      x: {
        beginAtZero: true,
        //Ticks allows to customize behavior / appearance of axis ticks
        ticks: {
          //Callback allows to customize apppearance of each axis tick
          //Receives tick value as argument
          callback: (value) => {
            if (Number.isInteger(value)) {
              return value; //Display only whole numbers on the x-axis
            }
            return ''; //Hide non-integer values (decimals)
          },
        },
      }
      },
    };
  
    return (
      <div className={className}>
        <h2>Top {numMetrics} {metricName}s</h2>
        <Pie data={chartData} options={chartOptions} />
        {/* Drop down to allow selecting number of metrics */}
        <select value={numMetrics} onChange={(event) => handleNumMetricSelect(parseInt(event.target.value))}>
            {/* Array(15) creates undefined elements
            [...Array(15)] used to convert empty slots into actual elements so map will work!*/}
            {[...Array(15)].map((_, index) => (
            // Give each element a unique key!
              <option key={index + 1} value={index + 1}>
                Top {index + 1} {index === 0 ? `${metricName}` : `${metricName}s`}
              </option>
            ))}
          </select>
      </div>
    );
  };
  export default MetricsChart;