import React, {useState, useEffect} from 'react';
import MapLayout from '../mapLayout';
import './account.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useAccessToken } from '../../globalStateContext';
import Modal from 'react-modal';
import AllArticles from '../dashboard/allArticles';
import MetricsChart from './metricsChart';

//Component for displaying the user's profile page
function ProfilePage() {
    const [selectedOption, setSelectedOption] = useState('account'); // Initial selection

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

//Return the component depending on what the user selected
return (
    <>
        {/* Menu to allow user to display different profile information */}
        <div className="profile-menu">
            <div
                className={`menu-item ${selectedOption === 'account' ? 'active' : ''}`}
                onClick={() => handleOptionChange('account')}
            >
                Account Info
            </div>
            <div
                className={`menu-item ${selectedOption === 'liked' ? 'active' : ''}`}
                onClick={() => handleOptionChange('liked')}
            >
                Liked Articles
            </div>
            <div
                className={`menu-item ${selectedOption === 'activity' ? 'active' : ''}`}
                onClick={() => handleOptionChange('activity')}
            >
                Activity
            </div>
            <div
                className={`menu-item ${selectedOption === 'resetPassword' ? 'active' : ''}`}
                onClick={() => handleOptionChange('resetPassword')}
            >
                Reset Password
            </div>
        </div>
        {/* Display the selected option and associated title */}
        <h2 className="profileTitle"><span className="capitalize">{selectedOption}</span></h2>
        <div className="profile-main">
            {selectedOption === 'account' && <AccountInfo />}
            {selectedOption === 'liked' && <LikedArticles />}
            {selectedOption === 'activity' && <Activity />}
            {selectedOption === 'resetPassword' && <ResetPassword />}
        </div>
    </>
);
}

//Component for displaying user's account information
function AccountInfo() {
    //State variables for keeping track of user information
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email,setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [birthday, setBirthday] = useState('');

    //State variable to keep track of whether edit profile button is clickable (if user made change to info)
    const [submitClickable, setSubmitClickable] = useState(false);
    
    //State variable to show delete confirmation (for deleting account)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    //State variable whether request for information is completed (to ensure user info is fetched)
    const [completedProfileFetch, setCompletedProfileFetch] = useState(false);

    const {makeRequest} = useAccessToken(); //Makerequest function from context to avoid handling token/session info

    //Do a useEffect to ensure it only runs initially
    useEffect(() => {
        //Check if account info is cached in local storage
        const cachedFirstName = localStorage.getItem('saitoFirstName');
        const cachedLastName = localStorage.getItem('saitoLastName');
        const cachedEmail = localStorage.getItem('saitoEmail');
        const cachedUsername = localStorage.getItem('saitoUsername');
        const cachedBirthday = localStorage.getItem('saitoBirthday');
        //If account info not cached in local storage, make request to backend!
        if (!cachedFirstName || !cachedLastName || !cachedEmail || !cachedUsername || !cachedBirthday)
        {
            async function fetchUserInfo() {
                const profileInformationResponse=await makeRequest("GET", "/api/returnProfile");
                setCompletedProfileFetch(true);
                //Cache user information if there is a response
                if (!profileInformationResponse.error)
                {
                    const profileInformation = JSON.parse(profileInformationResponse.data);
                    localStorage.setItem('saitoFirstName',profileInformation.firstName);
                    localStorage.setItem('saitoLastName',profileInformation.lastName);
                    localStorage.setItem('saitoEmail',profileInformation.email);
                    localStorage.setItem('saitoUsername',profileInformation.username);
                    localStorage.setItem('saitoBirthday',profileInformation.birthday);
                    setFirstName(profileInformation.firstName);
                    setLastName(profileInformation.lastName);
                    setEmail(profileInformation.email);
                    setUsername(profileInformation.username);
                    setBirthday(profileInformation.birthday);
                }
                //If there is error log it
                else {
                    console.log("Error: ",profileInformationResponse.error);
                }
            }
            fetchUserInfo();
        }
        //If data is cached, just set state variables without fetching
        else
        {
            setFirstName(cachedFirstName);
            setLastName(cachedLastName);
            setEmail(cachedEmail);
            setUsername(cachedUsername);
            setBirthday(cachedBirthday);
            setCompletedProfileFetch(true);
        }
    },[])
    //Don't display information until fetch completed (from backend or local cache)
    if (!completedProfileFetch){
        return <div>Loading...</div>
    }
    //Function to handle editing profile information
    const handleSubmit = () => {
        const fieldsToUpdate = {
            "firstName":firstName,
            "lastName":lastName
        };
        //Make POST request to edit information at the returnProfile API endpoint
        async function updateUserProfile(){
            const response = await makeRequest("POST","/api/returnProfile",fieldsToUpdate);
            //Update local cache if response is success
            if (!response.error) {
                localStorage.setItem("saitoFirstName",firstName);
                localStorage.setItem("saitoLastName",lastName);
            }
        }
        updateUserProfile();        
    }
    //Function to handle when user deletes account
    const handleDeleteAccount = () => {
        async function deleteAccount() {
            const response = await makeRequest("DELETE", "/api/deleteAccount");
            //If there's not an error, clear cache!
            if (!response.error)
            {
                localStorage.clear();
                sessionStorage.clear();
                window.location.replace("/login");
            }
            //If there's error deleting account, handle it
            else{
                console.log("Error deleting account");
            }
        }
        deleteAccount();
    }
return (
    <div className="accountInfoContainer">
        <label htmlFor="email">Email</label>
        {/* Setting disabled for read only (for email) */}
        <TextField variant="outlined" id="email" disabled value={email}/>
        <label htmlFor="username">Username</label>
        {/* Setting disabled for read only (for username) */}
        <TextField variant="outlined" id="username" disabled value={username}/>
        <label htmlFor="firstName">First Name</label>
        <TextField variant="outlined" id="firstName" value={firstName} onChange={(e) => {setFirstName(e.target.value); setSubmitClickable(true)}}/>
        <label htmlFor="lastName">Last Name</label>
        <TextField variant="outlined" id="lastName" value={lastName} onChange={(e) => {setLastName(e.target.value); setSubmitClickable(true)}}/>
        <label htmlFor="birthday">Birthday</label>
        <TextField variant="outlined" id="birthday" value={birthday} disabled />
        {/* Make it so submit is only clickable if a field has been changed */}
        {submitClickable ? <div className="accountSubmitButton"><Button onClick={handleSubmit} variant="contained" fullWidth>Edit Profile</Button></div>
        : <div className="accountSubmitButton"><Button onClick={handleSubmit} variant="contained" fullWidth disabled>Edit Profile</Button></div>}
        <div className="accountDeleteButton"><Button onClick={() => setShowDeleteConfirmation(true)} variant="contained" fullWidth style={{backgroundColor:'red'}}>Delete Account</Button></div>
        
        {/* Have Modal to display whenever user presses delete account (to confirm deletion) */}
        <Modal isOpen={showDeleteConfirmation} onRequestClose={() => setShowDeleteConfirmation(false)} contentLabel="Delete Account" className="deleteAccountConfirmation">
            <h1>Are you sure you want to delete account?</h1>
            <div className="deleteAccountAfterConfirmationButton"><Button onClick={handleDeleteAccount} variant="contained" fullWidth>Yes, Delete Account</Button></div>
            <div><Button onClick={() => {setShowDeleteConfirmation(false)}} variant="contained" fullWidth>Cancel</Button></div>
        </Modal>
    </div>
    );
}

//Component to return user's liked articles
function LikedArticles() {
    //State variable for keeping track of liked articles and when they are fetched (to not display prematurely)
    const [likedArticlesFetched,setLikedArticlesFetched]=useState(false);
    const [likedArticles,setLikedArticles]=useState([]);
    //State variable for unique articles (articles repeat since many-many for category&location)
    const [uniqueLikedArticles,setUniqueLikedArticles]=useState([]);
    const {makeRequest} = useAccessToken();

    //Fetch liked articles on initial mount
    useEffect(()=>{
        //Fetch user liked articles
        async function fetchLikedArticles() {
            try {
                const returnedLikedArticles=await makeRequest("GET","api/returnLikedArticles?includeArticles=true");
                //Handle error if fetch is unsuccessful
                if (returnedLikedArticles.error){
                    throw new Error("error");
                }
                //Set state variable to fetched liked articles
                setLikedArticles(JSON.parse(returnedLikedArticles.data));
                console.log("LIKED ARTICLES ARE: ", JSON.parse(returnedLikedArticles.data));
            }
            //Handle other error
            catch (error) {
                console.log("ERROR: ", error);
            }
            setLikedArticlesFetched(true);
        }
        fetchLikedArticles();
    },[])

    //Extract only the unique article IDs! UseEffect for whenever likedArticles change
    useEffect(()=> {
        if (likedArticles.length>0)
        {
            //Set can only have unique values
            const seenIds=new Set();
            //Filter out unique IDs using the set
            setUniqueLikedArticles(likedArticles.filter((arr) => {
                const id=arr[0] //Filter based on unique IDs

                //If the set doesn't have ID, add to set and return true to be included in filter
                if (!seenIds.has(id)) {
                    seenIds.add(id);
                    return true;
                }
                return false; //If set has ID, return false to be excluded from filter
            }));
        }

    },[likedArticles])

    //If articles haven't been fetched yet, display loading
    if (!likedArticlesFetched) {
        return (<h1>Loading...</h1>);
    }

    //If no liked articles, let user know
    if (likedArticles.length===0) {
        return(<h1>No liked articles yet!</h1>)
    }
    //Only display unique liked articles (since same article is tied to different categories/locations)
    //This means category/location may be different than filter user used; however, article itself will always be correct.
    return <AllArticles articles={uniqueLikedArticles} fromDashboard={false} />;
}

function Activity() {
    const {makeRequest}=useAccessToken();
    const [categoryMetrics,setCategoryMetrics]=useState([]);
    const [locationMetrics,setLocationMetrics]=useState([]);
    const [numViewedArticles,setNumViewedArticles]=useState(0);
    const [completedFetch, setCompletedFetch]=useState(false);
    //Fetch metrics only on initial mount
    useEffect(()=>{
        async function fetchUserMetrics()
        {
            const userMetrics= await makeRequest("GET","api/userMetrics");
            if (!userMetrics.error)
            {
                console.log(userMetrics.data);
                setCategoryMetrics(userMetrics.data["categoryMetrics"]);
                setLocationMetrics(userMetrics.data["locationMetrics"]);
                if (userMetrics.data["userViewMetrics"])
                {
                    setNumViewedArticles(userMetrics.data["userViewMetrics"][0]);
                }
            }
            //Handle error
            else 
            {
                console.log("ERROR: ", userMetrics.error);

            }
            setCompletedFetch(true);
        }
        fetchUserMetrics();
    },[]);

    if (!completedFetch)
    {
        return <div>Loading...</div>;
    }
    return (
    <div className='activityContainer'>
        <h3 className='activityViewedArticles'>You have read {numViewedArticles} unique articles</h3>
        <MetricsChart userMetrics={categoryMetrics} metricName="Sector" metricIndex={0} className='activitySectorGraph'/>
        <MetricsChart userMetrics={locationMetrics} metricName="Continent" metricIndex={0} className='activityContinentGraph'/>
        <MetricsChart userMetrics={locationMetrics} metricName="Country" metricIndex={1} className='activityCountryGraph'/>
    </div>
    );
}

//Component for resetting password (after user's logged in)
function ResetPassword(){
    //State variables for handling password information
    const [currentPassword,setCurrentPassword]=useState('');
    const [newPassword,setNewPassword]=useState('');
    const [confirmNewPassword,setConfirmNewPassword]=useState('');
    //State variable for making button clickable based on field values
    const [buttonClickable, setButtonClickable] = useState(false);
    const {makeRequest} = useAccessToken();//Function for handling requests (so don't have to worry about tokens)

    //Useffect to have validate if button is clickable after inputs change
    useEffect(()=>{
        if (currentPassword && newPassword && confirmNewPassword)
        {
            if (newPassword===confirmNewPassword && newPassword !== currentPassword)
            {
                setButtonClickable(true);
            }
            else
            {
                setButtonClickable(false);
            }
        }
        else{
            setButtonClickable(false);
        }
    },[currentPassword,newPassword,confirmNewPassword]);

    //Function for handling when user submits tries to change password
    const handlePasswordChange = () => {
        //Funtion to send request to backend
        async function changePassword()
        {
            const response=await makeRequest("POST","api/resetPasswordFromProfile",{"password":currentPassword,"newPassword":newPassword});
            //If successful (no error), display message and reset input states
            if (!response.error)
            {
                console.log("PASSWORD RESET!");
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
            }
            //Handle error if not successful
            else
            {
                console.log("ERROR RESETTING PASSWORD");
            }
        }
        //Ensure something has been entered for all fields
        if (currentPassword && newPassword && confirmNewPassword)
        {   
            //Confirm that confirmed and new password match (=== ensures same type (5!='5' for example))
            if (newPassword===confirmNewPassword)
            {
                changePassword();
                return;
            }
        }
        //If all fields not entered propery, display error
        console.log("ENSURE ALL FIELDS ENTERED PROPERLY!");
    }

    return (
        <div className="resetPasswordContainer">
            <label htmlFor="currentPassword">Enter Current Password</label>
            <TextField variant="outlined" id="currentPassword" type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />

            <label htmlFor="newPassword">Enter New Password</label>
            <TextField variant="outlined" id="newPassword" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>

            <label htmlFor="confirmPassword">Confirm New Password</label>
            <TextField variant="outlined" id="confirmPassword" type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)}/>

            {/* Have button clickable depending on associated state variable */}
            {buttonClickable ? <Button onClick={handlePasswordChange} variant="contained" fullWidth>Change Password</Button>
            : <Button onClick={handlePasswordChange} variant="contained" fullWidth disabled>Change Password</Button>}

        </div>
    )
}

function AccountPage()
{
    return (
    <MapLayout pageName="Account Page">
        <ProfilePage />
    </MapLayout>
    )
}
export default AccountPage;