import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"; // Include this CSS file for styling

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-content">
        <div className="footer-logo">
          <p className="footer-text-small">© Trendcaster.ca 2024</p>
        </div>
        <div className="footer-links">
          <Link to="/toc" className="footer-link">
            Terms and Conditions
          </Link>
          <Link to="/privacy-policy" className="footer-link">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
