import React, { useState, useEffect, useRef } from 'react';
import { GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import { useAccessToken } from '../globalStateContext';
import './login.css'
import { Navigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; //Styles for datepicker
import Modal from 'react-modal';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import InformationHelper from './InformationHelper';
import Header from './header';
import Footer from './footer';
import { Link } from "react-router-dom";


//Component for allowing users to pick birthday
const BirthdayPicker = ({birthday, setBirthDay}) => {
  const handleDateChange = (date) => {
    console.log("BIRTHDAY: ",date.toLocaleDateString());
    setBirthDay(date);
  };

  //Set max date to 13 years ago (to allow only users of 13 years or older)
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear()-13);

  //Info message to display users
  const infoMessage = "Users must be at least 13 years of age to sign up";

  //Add label and DatePicker component (input box that displays interactive calendar when pressed)
  return (
    <>
    <label htmlFor="birthday">Enter Birthday: <span><InformationHelper helpMessage={infoMessage}/></span></label>
    <DatePicker
        selected={birthday}
        onChange={handleDateChange}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        className="customDatePicker inputStyle"
        maxDate={maxDate}
    />
    </>
  );
}

//Component for allowing user to enter only birthday
function EnterBirthday()
{
  //Have modal always show (so user has to submit birthday)
  const showModal=true;
  const {makeRequest, expireToken} = useAccessToken();
  const [birthday,setBirthDay] = useState(null);

  //Function to submit birthday to back end
  const submitBirthday = () => {
    async function sendBirthday() {
      //User already has token generated from django, so just need to see if birthday valid and get onboard!
      const response = await makeRequest("POST",'/api/addUserInfo',{'birthday':birthday});
      //If no error, log user (redirect them to home page)!
      if (!response.error)
      {
        // Handle successful login, e.g., redirect to home page
        window.location.href = '/';
      }
      //Else handle error!
      else
      {
        //Expire the user's token and send them back to login if not valid date
        expireToken();
        window.location.href = '/login'
        console.log("Error: ",response.error);
      }

    }
    sendBirthday();
  }
  return(
    <Modal isOpen={showModal} className="enterBirthdayModal">
      <div className="enterBirthdayModalContent">
      <h2>Enter your birthday to proceed</h2>
        <BirthdayPicker birthday={birthday} setBirthDay={setBirthDay}/>
        {birthday ? <Button onClick={submitBirthday} variant="contained" fullWidth>Enter Birthday</Button>
        : <Button onClick={submitBirthday} variant="contained" fullWidth disabled>Enter Birthday</Button>}
      </div>
    </Modal>
  )
}

//Component for logging in user with google sign in
function GoogleLoginButton({className, saveAccessToken, saveRefreshToken, setErrorMessage}) {
  //State variable to see if user needs to enter birthday
  const [missingBirthday, setMissingBirthday] = useState(false);

  //Method to handle google's response
  const responseGoogle = (response) => {
    //Report if there's an error
    if (response.error) {
      console.log('Google Sign-In Error:', response.error);
    } 
    //If no error, send user's google access token (response.credential) to django backend for verification
    else {
      const accessToken = response.credential;
      //Call a function to fetch user information
      fetchUserInfo(accessToken);
    }
  };

  //Function to actually send access token to django backend
  const fetchUserInfo = async (accessToken) => { 
    try {
      //Send google access token in body of POST for security reasons (JSON.stringify used to format body in react)
      //Await used to wait for response
      const res = await fetch(`${process.env.REACT_APP_DJANGO_BACKEND_HOST}/api/googleLogin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({access_token: accessToken}),
      });
      //If the response is success, redirect user to home page
      if (res.ok) {
        //Additional await since res.json() is asynchronous operation that returns a promise (waiting for promise to resolve to get actual data)
        //Wait for parsed JSON data to be available after response has been received
        const response = await res.json();
        saveAccessToken(response.access_token);
        saveRefreshToken(response.refresh_token);
        
        // Handle successful login, e.g., redirect to home page
        window.location.href = '/';
      }
      //If response is 403 (forbidden, make the user enter their birthday!)
      else if (res.status==403) {
        const response = await res.json();
        console.log("RESPONSE!!!: ",response);
        saveAccessToken(response.access_token);
        saveRefreshToken(response.refresh_token);
        setMissingBirthday(true);
      }
      //If response is not a success, report error 
      else {
        console.log('Google Login Failed:', res.statusText);
      }
    } catch (error) {
      setErrorMessage("Error! Please try again");
      console.log('Error sending access token:', error);
    }
  
  };

  if (missingBirthday)
  {
    return <EnterBirthday />
  }
  /*
  return (
    <div className={className}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleLogin
          buttonText="Sign in with Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </GoogleOAuthProvider>
    </div>
  );*/
};

function LoginPage() {
  const {saveAccessToken, saveRefreshToken} = useAccessToken();
  //State variables to keep track of whether create account or login page
  const [displayCreate, setDisplayCreate] = useState(false)

  //State variables to keep track of username and password
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  //State variables to keep track of other information (for creating account)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [bio, setBio] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');

  //State variable for keeping track of error
  const [errorMessage, setErrorMessage] = useState('');

  //State variable for keeping track of birthday
  const [birthday, setBirthDay] = useState(null);

  //Method for submitting login
  const submitLogin = async e => {
    setErrorMessage('');
    e.preventDefault();
    const userCredentials = {
      username: username,
      password: password
    };

    //POST Request
    const res = await fetch(`${process.env.REACT_APP_DJANGO_BACKEND_HOST}/api/login`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(userCredentials),
    });

    //If the response is success, redirect user to home page
      if (res.ok) {
      //Additional await since res.json() is asynchronous operation that returns a promise (waiting for promise to resolve to get actual data)
      //Wait for parsed JSON data to be available after response has been received
      const response = await res.json();
      saveAccessToken(response.access_token);
      saveRefreshToken(response.refresh_token);
      // Handle successful login, e.g., redirect to home page
      window.location.href = '/';

    }
    //If response is not a success, report error and wipe password field
    else {
      setErrorMessage("Login Failed! Please try again.")
      setPassword('');
    }
  }

  //Method for creating the user's account
  const submitCreateAccount = async e => {
    e.preventDefault();
    try {
      setErrorMessage('');

      //Confirm that the password and confirmed password match
      if (password !== confirmedPassword)
      {
        setErrorMessage("Passwords must match!");
        return;
      }
      const accountInfo = {
        username: username,
        password: password,
        firstName: firstName,
        lastName: lastName,
        email: email,
        birthday: birthday
      }
      //POST Request
    const res = await fetch(`${process.env.REACT_APP_DJANGO_BACKEND_HOST}/api/createAccount`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(accountInfo),
    });
    //If the response is success, redirect user to home page
      if (res.ok) {
      //Additional await since res.json() is asynchronous operation that returns a promise (waiting for promise to resolve to get actual data)
      //Wait for parsed JSON data to be available after response has been received
      const response = await res.json();
      saveAccessToken(response.access_token);
      saveRefreshToken(response.refresh_token);
      // Handle successful login, e.g., redirect to home page
      window.location.href = '/';
    }
    //Handle error
    else {
      const response = await res.json();
      //Set to response's error or generic error if no error with response.
      setErrorMessage(response.error ? response.error : "Error! Please try again!");
    }
    }
    catch {
      setErrorMessage("Error! Try again");
    }
  }
  const updateCreateOrLogin = () => {
    setDisplayCreate(!displayCreate);
    setErrorMessage('');
  }

    //------------Method for submitting user info (email and bio/message)
    const submitUserInfo = async (e, source) => {
      setEmail('');
      e.preventDefault();
      const emailInfo={
          "email":email,
          "bio": bio,
          "source":source,
      };
      console.log(`${process.env.REACT_APP_LANDING_PAGE_BACKEND}`);
      //POST Request
      const res = await fetch(`${process.env.REACT_APP_LANDING_PAGE_BACKEND}`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      'x-functions-key': `${process.env.REACT_APP_LANDING_PAGE_KEY}`
      },
      body: JSON.stringify(emailInfo),
      });

      //If the response is success, let user know their email was saved successfully!
      if (res.ok) {
          setEmailMessage("*Info Submitted Successfully!")
      }
      //If response is not a success, report error and wipe password field
      else {
      setEmailMessage("*Error adding info! Please try again.")
      setEmail('');
      }
  }
  
  return (
    <div>
<div className ="login-page-heading">
<Header />

</div>

    {/* Start by displaying login page */}
    {!displayCreate ? (
      <div className="loginPageContainer">

        <div className="loginPageFormContainer">
        <form className="loginPageCredForm" onSubmit={submitLogin} method="POST">
            <h1 className="loginPageTitle">Login</h1>
            <label htmlFor="username">Username</label>
            <input type="text" name="username" id="username" className="inputStyle" onChange={(e) => setUsername(e.target.value)} value={username}></input>
            <label htmlFor="password">Password</label>
            <input type="password" name="password" id="password" className="inputStyle" onChange={(e) => setPassword(e.target.value)} value={password}></input>

            <input type="submit" className="loginPageNavlink" value="Log In"></input>
       </form>

       <div className="disclaimer">
          <label className="loginPageDisclaimer" htmlFor="loginPageLink">
            By signing in to Trendcaster, you agree to our 
            <a href="/toc" target="_blank" className="link">Terms</a> 
            and 
            <a href="/privacy-policy" target="_blank" className="link">Privacy Policy</a>.
          </label>
        </div>


        <div className="accessContainer">
          <label className="loginPageText" htmlFor="loginPageLink">Don't have an account? </label>
          <span className="loginPageLink" id="loginPageLink" onClick={updateCreateOrLogin}>
            Request Access
          </span>
        </div>

{/* <div className="loginContainer">
      <label className="loginPageText" htmlFor="loginPageLink">Don't have an account? </label>
      <span className="loginPageLink" id="loginPageLink" onClick={updateCreateOrLogin}>
        Sign up
      </span>
    </div> */}


{/*           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <hr style={{ flex: 1, border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }} />

          <span style={{ padding: '0 10px', fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.5)' }}>or</span>

        <hr style={{ flex: 1, border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }} />

      </div> */}


          <GoogleLoginButton saveAccessToken={saveAccessToken} saveRefreshToken={saveRefreshToken} className="googleLogin" setErrorMessage={setErrorMessage} />

          {errorMessage && <div className="errorMessage">{errorMessage}</div>}
        </div>
      </div>
    )
    //If user selected to display create account page 
    
    : (
      <div className="loginPageContainer">
      
      <div className="loginPageFormContainer">
      <h1 className="loginPageTitle">Request Access</h1>
      <form className="loginPageCredForm" onSubmit={(e)=>submitUserInfo(e,"signUpPage")} method="POST">
          {/* Honeypot field to catch bots that auto fill fields */}
          <input type="text" name="verifier" style={{display:"none"}} value="" /> 
          <label htmlFor="email">Email:</label>
          <input type="text" name="email" id="email" className="inputStyle" maxlength="100" 
          onChange={(e)=>setEmail(e.target.value)}
          value={email}
          ></input>
          <label htmlFor="biography">Bio</label>
          <textarea name="biography" id="biography" 
            className="bioInputStyle" onChange={(e) => setBio(e.target.value)} 
            maxLength="250"
            value={bio}
            ></textarea> {/*This needs to be renamed to the emai variable*/ }
          <input type="submit" className="loginPageNavlink" value="Create Account"></input>
          {emailMessage && <div style={{color: "red"}}>{emailMessage}</div>}
        </form>

        

{/*       <form className="loginPageCredForm" onSubmit={submitCreateAccount} method="POST">
          <label htmlFor="firstName">Enter First Name:</label>
          <input type="text" name="firstName" id="firstName" className="inputStyle" onChange={(e) => setFirstName(e.target.value)}></input>
          <label htmlFor="lastName">Enter Last Name:</label>
          <input type="text" name="lastName" id="lastName" className="inputStyle" onChange={(e) => setLastName(e.target.value)}></input>
          <label htmlFor="email">Enter Email:</label>
          <input type="text" name="email" id="email" className="inputStyle" onChange={(e) => setEmail(e.target.value)}></input>
          <label htmlFor="username">Enter Username:</label>
          <input type="text" name="username" id="username" className="inputStyle" onChange={(e) => setUsername(e.target.value)}></input>
          <BirthdayPicker birthday={birthday} className={"inputStyle"} setBirthDay={setBirthDay} />
          <label htmlFor="password">Enter Password:<span><InformationHelper 
          helpMessage="Password must be at least 8 characters long and contain at least 1 number, 1 lowercase, 1 uppercase and 1 special character (!#$%&'()*+,-./:;<=>?@[\]^_{|}~"/></span></label>
          <input type="password" name="password" id="password" className="inputStyle" onChange={(e) => setPassword(e.target.value)}></input>
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input type="password" name="confirmPassword" id="confirmPassword" className="inputStyle" onChange={(e) => setConfirmedPassword(e.target.value)}></input>
          <input type="submit" className="loginPageNavlink" value="Create Account"></input>
        </form> */}
        
        <div className="loginContainer">
      <label className="loginPageText" htmlFor="loginPageLink">Already have an account?</label>
      <span className="loginPageLink" id="loginPageLink" onClick={updateCreateOrLogin}>
        Sign In
      </span>

      
    </div>
  
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <hr style={{ flex: 1, border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }} />


        <hr style={{ flex: 1, border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }} />

      </div>
        <GoogleLoginButton saveAccessToken={saveAccessToken} saveRefreshToken={saveRefreshToken} className="googleLogin" setErrorMessage={setErrorMessage}/>
        {errorMessage && <div className="errorMessage">{errorMessage}</div>}
      </div>
    </div>
    
  )
  
  
    }

  
  </div>



);
};

export default LoginPage;


// import React, {useCallback} from 'react';
// import { GoogleLogin } from 'react-google-login';
// import { post } from 'utils/sdk';

// export const validateTokenAndObtainSession = ({ data, idToken }) => {
//   const headers = {
//     Authorization: idToken,
//     'Content-Type': 'application/json'
//   };

//   return post('users/init/', data, { headers });
// };

// const onGoogleLoginSuccess = useCallback(
//     response => {
//       const idToken = response.tokenId;
//       const data = {
//         email: response.profileObj.email,
//         first_name: response.profileObj.givenName,
//         last_name: response.profileObj.familyName
//       };
  
//       validateTokenAndObtainSession({ data, idToken })
//         .then(handleUserInit)
//         .catch(notifyError);
//     },
//     [handleUserInit]
//   );
// const Login = () => {
//   return (
//     <GoogleLogin
//       clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}  // your Google app client ID
//       buttonText="Sign in with Google"
//       onSuccess={onGoogleLoginSuccess} // perform your user logic here
//       onFailure={onGoogleLoginFailure} // handle errors here
//     />
//   );
// };
// export default Login;
