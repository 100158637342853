import React, { useState } from "react";
import Footer from "./footer";
import Header from "./header";
import { Link } from "react-router-dom"; // Ensure you have react-router-dom installed
import "./support.css"; // New CSS file for support page styling

function SupportPage() {
  const [openQuestions, setOpenQuestions] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("Account");

  const toggleQuestion = (index) => {
    setOpenQuestions((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((i) => i !== index); // Close if already open
      } else {
        return [...prevState, index]; // Open the question
      }
    });
  };

  const supportCategories = {
    Account: [
      { question: "How do I reset my password?", answer: "You can reset your password by clicking 'Forgot Password' on the login page and following the instructions sent to your email." },
      { question: "How do I update my account details?", answer: "Go to the account settings page to update your profile, email, or other account information." },
      { question: "How do I delete my account?", answer: "You can delete your account by contacting support through the 'Contact Us' page." },
      { question: "What if I forget my email?", answer: "Please contact support and provide proof of identity to retrieve your email." },
    ],
    Features: [
      { question: "How do I use sentiment analysis?", answer: "Navigate to the dashboard, select 'Sentiment Analysis,' and upload or choose the desired data for processing." },
      { question: "Can I customize the filters?", answer: "Yes, you can customize filters such as time range, keywords, and regions in the dashboard." },
      { question: "How do I access the entity tracker?", answer: "The entity tracker is located under the 'Entity Overview' section in the analysis dashboard." },
      { question: "What is the keyword tracking feature?", answer: "Keyword tracking allows you to monitor specific terms and see trends over time." },
    ]
  };

  return (

    <section id="support-section" className="support-section"> 
       <Header />
      <div className="support-header">
        <h2 className="support-title">Support & Help Center</h2>
        <div className="support-categories">
          {Object.keys(supportCategories).map((category) => (
            <button
              key={category}
              className={`category-button ${currentCategory === category ? "active" : ""}`}
              onClick={() => {
                setCurrentCategory(category);
                setOpenQuestions([]); // Reset open questions when switching categories
              }}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      <div className="support-grid">
        {supportCategories[currentCategory].map((item, index) => (
          <div
            key={index}
            className={`support-item ${openQuestions.includes(index) ? "open" : ""}`}
            onClick={() => toggleQuestion(index)}
          >
            <div className="support-question">{item.question}</div>
            <div className="support-answer">{item.answer}</div>
          </div>
        ))}
      </div>

      <div className="support-footer">
        <p>
          Still need support? <Link to="/contact" className="contact-link">Contact us!</Link>
        </p>
      </div>

    </section>      
  );
}

export default SupportPage;
