import React from "react";
import Header from "../header.jsx"; // Ensure the path to your header component is correct
import Footer from "../footer.jsx"
import "./features.css";

function FeaturesPage() {
  const features = [
    {
      category: "Data Aggregation",
      description: "Aggregate data from diverse news sources.",
      benefit: "Comprehensive and unbiased insights from multiple perspectives.",
    },
    {
      category: "Sentiment Analysis",
      description: "Advanced sentiment analysis to detect positive, negative, and neutral tones.",
      benefit: "Understand public sentiment for strategic decision-making.",
    },
    {
      category: "Entity Tracking",
      description: "Identify and track mentions of people, organizations, and locations.",
      benefit: "Monitor key entities and uncover actionable insights.",
    },
    {
      category: "Keyword Tracking",
      description: "Analyze and visualize keyword trends over time.",
      benefit: "Stay updated on emerging topics and popular discussions.",
    },
    {
      category: "Bias Detection",
      description: "Detect and score potential bias in articles and reports.",
      benefit: "Ensure balanced and objective content evaluation.",
    },
    {
      category: "Custom Filters",
      description: "Apply filters such as date range, region, and category to refine your analysis.",
      benefit: "Focus on the most relevant data for your objectives.",
    },
    {
      category: "Export Options",
      description: "Export your data and reports in multiple formats such as CSV, and Excel.",
      benefit: "Seamlessly integrate insights into your workflow.",
    },
    {
      category: "Mobile Access",
      description: "Fully responsive platform accessible on mobile devices.",
      benefit: "Work on the go without losing access to powerful tools.",
    },
    {
      category: "Real-Time Updates",
      description: "Database updated every eight hours with the latest information.",
      benefit: "Stay informed with real-time insights and trends.",
    },
    {
      category: "Scalability",
      description: "Support for both individual and enterprise-level usage.",
      benefit: "Adaptable to your specific needs, whether small or large scale.",
    },
  ];

  const upcomingFeatures = [
    {
      feature: "Multilingual Analysis",
      description: "Analyze data in multiple languages for broader insights.",
    },
    {
      feature: "AI-Powered Summaries",
      description: "Automatically generate concise summaries of key articles.",
    },
    {
      feature: "Advanced Visualization",
      description: "Enhanced graphs and visualizations for deeper insights.",
    },
    {
      feature: "Automated Alerts",
      description: "Set up real-time alerts for specific topics or events.",
    },
    {
      feature: "Collaboration Tools",
      description: "Invite team members to collaborate on analysis projects.",
    },
  ];

  return (
    <section className="features-page">
      
      <div className="features-header">
            <Header />  
      </div>

      <h1 className="features-title">Explore Our Features</h1>
      <div className="features-table-container">
        <table className="features-table">
          <thead>
            <tr>
              <th>Feature</th>
              <th>Description</th>
              <th>Benefit</th>
            </tr>
          </thead>
          <tbody>
            {features.map((feature, index) => (
              <tr key={index}>
                <td className="feature-category">{feature.category}</td>
                <td>{feature.description}</td>
                <td>{feature.benefit}</td>
              </tr>
            ))}
          </tbody>
        </table>
      
      </div>
<Footer />
      
    </section>
  );
}

export default FeaturesPage;
